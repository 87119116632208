import React from "react";
import { Dialog, DialogContent } from "@mui/material";
import { setIsaAvanceFilterVisible } from "store/slices/clientScanReducer";
import { dispatch } from "store";

const AdvanceFilterDialog = ({ open, dialogContent }) => {
  const handleDialogClose = (event, reason) => {
    // If the dialog is closed due to backdrop click, dispatch the action
    if (reason === "backdropClick") {
      dispatch(setIsaAvanceFilterVisible(true));
    }
  };

  const AdvanceFilterBelow = window.location.href.includes("scan-detail")
    ? true
    : false;

  return (
    <Dialog
      open={open}
      maxWidth="lg" // Adjust dialog width
      fullWidth
      onClose={handleDialogClose}
      BackdropProps={
        {
          sx: {
            backgroundColor: "unset", // Normal background color when open, no background when closed
          },
        }
      }
      sx={{
        "& .MuiDialog-paper": {
          position: "absolute", // Fix the dialog position
          top: AdvanceFilterBelow ? "calc(100% - 370px)" : "150px",
          right: "61px", // Adjust right position from the screen
          width: "820px", // Customize the width
          margin: 0, // Remove default margin
          borderRadius: 0, // Remove default border radius
        },
      }}
    >
      <DialogContent sx={{ backgroundColor: "#fefffe", padding: "0px 25px" }}>
        {dialogContent}
      </DialogContent>
    </Dialog>
  );
};

export default AdvanceFilterDialog;
