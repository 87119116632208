import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";

const ConfirmUpdateInternalStatus = (props) => {
  const theme = useTheme(); // Access theme object
  const { loader } = props; // Use loading from props for simplicity

  return (
    <Dialog
      open={props?.open}
      onClose={props?.handleClose}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
      maxWidth="sm"
      fullWidth
      PaperProps={{
        elevation: 10, // Enhanced shadow for a professional look
        sx: {
          borderRadius: 8, // Rounded corners
          padding: "20px", // Padding for dialog content
        },
      }}
    >
      {/* Dialog Title */}
      <DialogTitle
        id="confirm-dialog-title"
        sx={{
          textAlign: "center",
          fontSize: 22,
          fontWeight: 700,
          color: theme.palette.text.primary,
          marginBottom: 2,
        }}
      >
        Confirm Update
      </DialogTitle>

      {/* Dialog Content */}
      <DialogContent sx={{ paddingX: 4, paddingBottom: 3 }}>
        <Typography
          variant="h6"
          sx={{
            fontWeight: 500,
            textAlign: "center",
            marginBottom: 2,
            color: theme.palette.text.primary,
            fontSize: "1rem", // Increased font size
          }}
        >
          Please note that when you proceed with bulk status update of findings
          using this feature, the system assumes that you have carefully gone
          through all the findings and are aware of the impact of the changes
          you are making.
        </Typography>
      </DialogContent>

      {/* Dialog Actions */}
      <DialogActions
        sx={{ justifyContent: "flex-end", paddingX: 4, paddingBottom: 3 }}
      >
        <Stack
          direction={"row"}
          justifyContent={"flex-end"}
          gap={2}
          sx={{ width: "100%" }}
        >
          {/* Cancel Button */}
          <LoadingButton
            size="small"
            onClick={props?.handleClose}
            loading={loader}
            sx={{
              color: theme.palette.text.secondary,
              border: `1px solid ${
                theme.palette.mode === "dark" ? "#b0bec5" : "#90a4ae"
              }`,
              backgroundColor: "transparent",
              borderRadius: 2,
              padding: "6px 16px",
              flex: 1,
            }}
          >
            Cancel
          </LoadingButton>

          {/* Confirm Button */}
          <LoadingButton
            size="small"
            variant="contained"
            autoFocus
            onClick={props?.confirmAction}
            loading={loader}
            sx={{
              borderRadius: 2,
              padding: "6px 16px",
              flex: 1,
            }}
          >
            Procced
          </LoadingButton>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmUpdateInternalStatus;
