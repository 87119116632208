import { yupResolver } from "@hookform/resolvers/yup";
import { ArrowDropDown as ArrowDropDownIcon } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import ReplayIcon from "@mui/icons-material/Replay";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  FormControl,
  Grid,
  Menu,
  MenuItem,
  TextField,
  IconButton,
} from "@mui/material";
import { IconFilterX } from "@tabler/icons-react";
import { IconFilterCheck } from "@tabler/icons-react";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/system";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { dispatch } from "store";
import FormControlSelect from "ui-component/extended/Form/FormControlSelect";
import InputLabel from "ui-component/extended/Form/InputLabel";
import { scanCategory } from "views/pages/consultant/assets/constant";
import * as yup from "yup";
import { scanMode } from "../constant";
import { useSelector } from "react-redux";

const validationSchema = yup.object().shape({
  // assetType: yup.string().required('Asset Type is required'),
  // assetSubType: yup.string().required('Asset Sub Type is required'),
});

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5),
  },
  textField: {
    width: "100%",
    marginBottom: theme.spacing(1),
  },
  chipContainer: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "4px",
    padding: theme.spacing(0.5),
    cursor: "text",
  },
  input: {
    flex: 1,
    minWidth: "120px",
    border: "none",
    outline: "none",
    padding: "12px 16px",
    fontSize: "16px",
  },
  card: {
    width: "350px",
    maxHeight: "200px",
    overflowY: "auto",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "4px",
    backgroundColor: "#fff",
    boxShadow:
      "0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)",
    borderRadius: "8px",
  },
}));

const AdvanceGenericScanFilterBar = (props) => {
  const theme = useTheme();
  const classes = useStyles();
  const [showCard, setShowCard] = useState(false);
  const [anchorEl, setAnchorEl] = useState({
    scanStatus: null,
    scanFilter: null,
    dateFilter: null,
    pendingFilter: null,
    scanMode: null,
    clientOrg: null,
    manager: null,
    tester: null,
    consultantScanStatus: null,
    publishedOnFilter: null,
    scanCategory: null,
  });
  //   const { isaAvanceFilterVisible } = useSelector(
  //     (state) => state?.clientScansState
  //   );

  const [names, setNames] = useState([]);
  const [currentName, setCurrentName] = useState("");
  const [selectedScanType, setSelectedScanType] = useState();
  const anchorRef = useRef(null);
  const inputRef = useRef(null);

  const { register, watch, setValue, reset } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    watch((value) => {
      props?.setScanFilter({
        ...props?.scanFilter,
        ...value,
      });
      setSelectedScanType(value?.scanType);
    });
    if (selectedScanType) {
      setValue("scanSubType", "");
    }
  }, [watch, selectedScanType]);

  useEffect(() => {
    if (props?.enableScanFilter) {
      dispatch(props?.scanTypeApi());
    }
    if (props?.enableClientStatus) {
      dispatch(props?.scanStatusApi());
    }
  }, []);

  useEffect(() => {
    if (selectedScanType) {
      dispatch(props?.scanSubTypeApi(selectedScanType));
    }
  }, [selectedScanType]);

  const handleDeleteName = (index) => {
    setNames(names?.filter((_, i) => i !== index));
  };

  const handleChangeDateValue = (field, date) => {
    const newDateFilter = { ...props?.createdOnFilter };
    newDateFilter[field] = date ? date.format("YYYY-MM-DD") : null;
    // If end date is not selected, default it to start date
    if (field === "startDate" && !newDateFilter.endDate && date) {
      newDateFilter.endDate = date.format("YYYY-MM-DD");
    }
    // If start date is not selected, default it to end date
    if (field === "endDate" && !newDateFilter.startDate && date) {
      newDateFilter.startDate = date.format("YYYY-MM-DD");
    }
    props?.setCreatedOnFilter(newDateFilter);
  };
  const handleChangePublishDateValue = (field, date) => {
    const newDateFilter = { ...props?.publishOnFilter };
    newDateFilter[field] = date ? date.format("YYYY-MM-DD") : null;
    // If end date is not selected, default it to start date
    if (
      field === "publishedStartDate" &&
      !newDateFilter.publishedEndDate &&
      date
    ) {
      newDateFilter.publishedEndDate = date.format("YYYY-MM-DD");
    }
    // If start date is not selected, default it to end date
    if (
      field === "publishedEndDate" &&
      !newDateFilter.publishedStartDate &&
      date
    ) {
      newDateFilter.publishedStartDate = date.format("YYYY-MM-DD");
    }
    props?.setPublishOnFilter(newDateFilter);
  };

  // const handleKeyPress = (event) => {
  //   if (event.key === "Enter" && currentName.trim()) {
  //     setNames([...names, currentName.trim()]);
  //     setCurrentName("");
  //   }
  // };

  const handleClick = (event, menuType) => {
    setAnchorEl({ [menuType]: event.currentTarget });
  };

  const handleClose = (name) => {
    if (name === "scanFilter") {
      setAnchorEl({
        scanFilter: null,
      });
    }
    if (name === "scanStatus") {
      setAnchorEl({
        scanStatus: null,
      });
    }
    if (name === "dateFilter") {
      setAnchorEl({
        dateFilter: null,
      });
    }
    if (name === "publishedOnFilter") {
      setAnchorEl({
        publishedOnFilter: null,
      });
    }
    if (name === "pendingFilter") {
      setAnchorEl({
        pendingFilter: null,
      });
    }
    if (name === "scanMode") {
      setAnchorEl({
        scanMode: null,
      });
    }
    if (name === "clientOrg") {
      setAnchorEl({
        clientOrg: null,
      });
    }
    if (name === "manager") {
      setAnchorEl({
        manager: null,
      });
    }
    if (name === "tester") {
      setAnchorEl({
        tester: null,
      });
    }
    if (name === "consultantScanStatus") {
      setAnchorEl({
        consultantScanStatus: null,
      });
    }
    if (name === "scanCategory") {
      setAnchorEl({
        scanCategory: null,
      });
    }
  };

  const handleScanStatusChange = (event, value) => {
    props?.setSelectedScanStatus(value);
  };

  const handleClientOrganizationChange = (event, value) => {
    props?.setSelectedClientOrganization(value);
  };

  const handleScanModeChange = (event, newValue) => {
    props.setSelectedScanMode(newValue || []); // Always set an empty array if the new value is null/undefined
    // props?.setSelectedScanMode(value);
  };

  const handleScanCategory = (event, value) => {
    props?.setSelectedScanCategory(value);
  };

  const handleChangeManagerName = (event, value) => {
    props?.setSelectedSecurityManagerName(value);
  };

  const handleChangeTesterName = (event, value) => {
    props?.setSelectedTesterName(value);
  };

  const handleConsultantScanStatus = (event, value) => {
    props?.setSelectedConsultantScanStatus(value);
  };

  const displaySMName =
    props?.consultantSMList?.length > 0 &&
    props?.consultantSMList?.map((value) => {
      return {
        smName: `${value.firstName} ${value.lastName} (${value.empCode})`,
        smId: value.userId,
      };
    });
  const displayTesterName =
    props?.consultantTesterList?.length > 0 &&
    props?.consultantTesterList?.map((value) => {
      return {
        testerName: `${value.firstName} ${value.lastName} (${value.empCode})`,
        testerId: value.userId,
      };
    });

  const handleReset = (type) => {
    switch (type) {
      case "scanFilter":
        props?.setScanFilter({
          scanType: "",
          scanSubType: "",
          scanName: "",
          scanRefNo: "",
        });
        reset();
        setValue("scanType", "");
        setValue("scanSubType", "");
        break;
      case "dateFilter":
        props?.setCreatedOnFilter({
          startDate: null,
          endDate: null,
        });
        break;
      case "publishedOnFilter":
        props?.setPublishOnFilter({});
        break;
      default:
        break;
    }
  };

  const handleMenuItemClick = (option) => {
    if (option === "All") {
      props?.setSelectedPendingOption({
        client: false,
        consultant: false,
      });
    } else if (option === "Client") {
      props?.setSelectedPendingOption({
        client: true,
        consultant: false,
      });
    } else if (option === "Consultant") {
      props?.setSelectedPendingOption({
        client: false,
        consultant: true,
      });
    }
    setAnchorEl(null);
  };

  const getPendingWithLabel = () => {
    if (props?.selectedPendingOption?.client) {
      return "Pending With Client";
    } else if (props?.selectedPendingOption?.consultant) {
      return "Pending With Consultant";
    } else {
      return "Pending With All";
    }
  };

  const commonButtonStyles = {
    padding: "3px 12px",
    fontSize: "0.9rem",
    borderRadius: "7px",
    "&:hover": {
      backgroundColor:
        theme.palette.mode === "dark" ? "white" : theme.palette.primary.light,
    },
    "&:active": {
      backgroundColor:
        theme.palette.mode === "dark" ? "white" : theme.palette.primary.light,
    },
    marginRight: "1px",
    backgroundColor:
      theme.palette.mode === "dark" ? "white" : theme.palette.primary.light,
    color: theme.palette.mode === "dark" ? "black" : "darkblue",
    marginTop: "6px",
  };

  const buttonStyles = (condition) => ({
    padding: "3px 12px",
    fontSize: "0.9rem",
    borderRadius: "7px",
    border: !condition
      ? theme.palette.mode === "dark"
        ? "1px solid #ffffff40"
        : "1px solid black"
      : "none",
    "&:hover": {
      backgroundColor: !condition
        ? ""
        : theme.palette.mode === "dark"
        ? "white"
        : theme.palette.primary.light,
    },
    "&:active": {
      backgroundColor: condition
        ? ""
        : theme.palette.mode === "dark"
        ? "white"
        : theme.palette.primary.light,
    },
    marginRight: "1px",
    backgroundColor: !condition
      ? ""
      : theme.palette.mode === "dark"
      ? "white"
      : theme.palette.primary.light,
    display: "flex",
    alignItems: "center",
    color: condition
      ? theme.palette.mode === "light"
        ? "darkblue"
        : "black"
      : theme.palette.mode === "dark"
      ? "white"
      : "black",
    marginTop: "6px",
  });

  return (
    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
      <div>
        {/* Pending With Button */}
        {props?.enablePendingWithFilter && (
          <Button
            aria-controls="pending-with-menu"
            aria-haspopup="true"
            onClick={(event) => handleClick(event, "pendingFilter")}
            sx={buttonStyles(
              props?.selectedPendingOption?.client ||
                props?.selectedPendingOption?.consultant
            )}
          >
            {getPendingWithLabel()}
            <ArrowDropDownIcon
              sx={{
                fontSize: "20px",
                marginLeft: "3px",
              }}
            />
          </Button>
        )}
        <Menu
          anchorEl={anchorEl?.pendingFilter}
          open={Boolean(anchorEl?.pendingFilter)}
          onClose={() => handleClose("pendingFilter")}
        >
          <MenuItem onClick={() => handleMenuItemClick("Client")}>
            Client
          </MenuItem>
          <MenuItem onClick={() => handleMenuItemClick("Consultant")}>
            Consultant
          </MenuItem>
          {(props?.selectedPendingOption?.client ||
            props?.selectedPendingOption?.consultant) && (
            <MenuItem onClick={() => handleMenuItemClick("All")}>All</MenuItem>
          )}
        </Menu>
      </div>
      {/* Name Button (MultiSelect)
      <div>
        <Button
          ref={anchorRef}
          onClick={() => setShowCard(!showCard)}
          sx={buttonStyles(names?.length)}
        >
          Scan Name
          <ArrowDropDownIcon
            sx={{
              fontSize: "20px",
              marginLeft: "3px",
            }}
          />
        </Button>
        <Popper
          open={showCard}
          anchorEl={anchorRef.current}
          placement="bottom-start"
          style={{ zIndex: 9999 }}
        >
          <ClickAwayListener onClickAway={() => setShowCard(false)}>
            <Card className={classes.card}>
              <CardContent>
                <div className={classes.chipContainer} onClick={focusInput}>
                  {names?.map((name, index) => (
                    <Chip
                      key={index}
                      label={name}
                      onDelete={() => handleDeleteName(index)}
                      className={classes.chip}
                    />
                  ))}
                  <input
                    ref={inputRef}
                    className={classes.input}
                    value={currentName}
                    onChange={(e) => setCurrentName(e.target.value)}
                    onKeyPress={handleKeyPress}
                  />
                </div>
              </CardContent>
            </Card>
          </ClickAwayListener>
        </Popper>
      </div> */}
      {/* Consultant Organization Button */}
      {props?.enableClientOrganizationFilter && (
        <Button
          color="primary"
          onClick={(event) => handleClick(event, "clientOrg")}
          sx={buttonStyles(props?.selectedClientOrganization?.length)}
        >
          Client Organization
          <ArrowDropDownIcon
            sx={{
              fontSize: "20px",
              marginLeft: "3px",
            }}
          />
        </Button>
      )}
      <Menu
        anchorEl={anchorEl?.clientOrg}
        open={Boolean(anchorEl?.clientOrg)}
        onClose={() => handleClose("clientOrg")}
      >
        <div style={{ padding: 10 }}>
          <FormControl sx={{ m: 1, width: 400 }}>
            <Autocomplete
              multiple
              options={props?.clientOrganizationList || []}
              getOptionLabel={(option) => option?.orgName}
              value={props?.selectedClientOrganization || []}
              onChange={handleClientOrganizationChange}
              renderTags={(tagValue, getTagProps) =>
                tagValue?.map((option, index) => (
                  <Chip
                    key={option?.id}
                    label={option?.orgName}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          </FormControl>
        </div>
      </Menu>
      {/* Manager Filter Button */}
      {props?.enableManagerFilter && (
        <Button
          color="primary"
          onClick={(event) => handleClick(event, "manager")}
          sx={buttonStyles(props?.selectedSecurityManagerName?.length)}
        >
          Manager
          <ArrowDropDownIcon
            sx={{
              fontSize: "20px",
              marginLeft: "3px",
            }}
          />
        </Button>
      )}
      <Menu
        anchorEl={anchorEl?.manager}
        open={Boolean(anchorEl?.manager)}
        onClose={() => handleClose("manager")}
      >
        <div style={{ padding: 10 }}>
          <FormControl sx={{ m: 1, width: 300 }}>
            <Autocomplete
              multiple
              options={displaySMName}
              getOptionLabel={(option) => option?.smName}
              value={props?.selectedSecurityManagerName || []}
              onChange={handleChangeManagerName}
              renderTags={(tagValue, getTagProps) =>
                tagValue?.map((option, index) => (
                  <Chip
                    key={option?.smId}
                    label={option?.smName}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          </FormControl>
        </div>
      </Menu>
      {/* Tester Filter Button */}
      {props?.enableTesterFilter && (
        <Button
          color="primary"
          onClick={(event) => handleClick(event, "tester")}
          sx={buttonStyles(props?.selectedTesterName?.length)}
        >
          Tester
          <ArrowDropDownIcon
            sx={{
              fontSize: "20px",
              marginLeft: "3px",
            }}
          />
        </Button>
      )}
      <Menu
        anchorEl={anchorEl?.tester}
        open={Boolean(anchorEl?.tester)}
        onClose={() => handleClose("tester")}
      >
        <div style={{ padding: 10 }}>
          <FormControl sx={{ m: 1, width: 300 }}>
            <Autocomplete
              multiple
              options={displayTesterName || []}
              getOptionLabel={(option) => option?.testerName}
              value={props?.selectedTesterName || []}
              onChange={handleChangeTesterName}
              renderTags={(tagValue, getTagProps) =>
                tagValue?.map((option, index) => (
                  <Chip
                    key={option?.testerId}
                    label={option?.testerName}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
              // sx={{
              //   "& .MuiAutocomplete-listbox": {
              //     maxHeight: "200px", // Set the maximum height of the dropdown
              //     overflow: "auto", // Add scroll bar if needed
              //   },
              // }}
              // PaperComponent={(props) => (
              //   <Paper
              //     {...props}
              //     style={{ maxHeight: 200, overflow: "auto" }}
              //   />
              // )}
            />
          </FormControl>
        </div>
      </Menu>
      {/* Scan Filter Button */}
      {props?.enableScanFilter && (
        <Button
          color="primary"
          onClick={(event) => handleClick(event, "scanFilter")}
          sx={buttonStyles(
            props?.scanFilter?.scanName ||
              //   props?.scanFilter?.scanRefNo ||
              props?.scanFilter?.scanSubType ||
              props?.scanFilter?.scanType
          )}
        >
          Scan
          <ArrowDropDownIcon
            sx={{
              fontSize: "20px",
              marginLeft: "5px",
            }}
          />
        </Button>
      )}
      <Menu
        anchorEl={anchorEl?.scanFilter}
        open={Boolean(anchorEl?.scanFilter)}
        onClose={() => handleClose("scanFilter")}
      >
        <div style={{ width: 550, padding: 15 }}>
          {" "}
          <Grid container spacing={2}>
            <Grid item xs={props.enableScanSubTypeFilter ? 6 : 12}>
              <InputLabel>Type:</InputLabel>
              <FormControlSelect
                itemsList={props?.scanTypes}
                optionLabel={"scanTypeDescription"}
                optionValue={"scanTypeId"}
                {...register("scanType")}
                value={props?.scanFilter?.scanType || ""}
                name="scanType"
              />
            </Grid>
            {props.enableScanSubTypeFilter && (
              <Grid item xs={6}>
                <InputLabel>Sub-Type:</InputLabel>
                <FormControlSelect
                  disabled={
                    !(props?.scanSubTypes?.length > 0 && selectedScanType)
                  }
                  itemsList={props?.scanSubTypes || []}
                  optionLabel={"scanSubTypeDescription"}
                  optionValue={"scanSubTypeId"}
                  {...register("scanSubType")}
                  value={props?.scanFilter?.scanSubType || ""}
                  name="scanSubType"
                />
              </Grid>
            )}
            <Grid item xs={props.enableScanSubTypeFilter ? 6 : 12}>
              <InputLabel>Name</InputLabel>
              <TextField
                name="scanName"
                variant="outlined"
                value={props?.scanFilter?.scanName || ""}
                {...register("scanName")}
                fullWidth
                autoComplete="off"
              />
            </Grid>
            {props.enableScanRefFilter && (
              <Grid item xs={6}>
                <InputLabel>Scan Ref Code</InputLabel>
                <TextField
                  variant="outlined"
                  value={props?.scanFilter?.scanRefNo || ""}
                  name="scanRefNo"
                  {...register("scanRefNo")}
                  fullWidth
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Box display="flex" justifyContent="flex-end" sx={{ gap: 2 }}>
                <Button
                  size="small"
                  color="primary"
                  variant="contained"
                  onClick={() => handleReset("scanFilter")}
                  startIcon={
                    <ReplayIcon
                      sx={{
                        fontSize: "20px",
                      }}
                    />
                  }
                  sx={{
                    minWidth: 90,
                  }}
                >
                  Reset
                </Button>
              </Box>
            </Grid>
          </Grid>
        </div>
      </Menu>
      {/* Client Status Button */}
      {props?.enableClientStatus && (
        <Button
          color="primary"
          onClick={(event) => handleClick(event, "scanStatus")}
          sx={buttonStyles(props?.selectedScanStatus?.length)}
        >
          Status
          <ArrowDropDownIcon
            sx={{
              fontSize: "20px",
              marginLeft: "3px",
            }}
          />
        </Button>
      )}
      <Menu
        anchorEl={anchorEl?.scanStatus}
        open={Boolean(anchorEl?.scanStatus)}
        onClose={() => handleClose("scanStatus")}
      >
        <div style={{ padding: 10 }}>
          <FormControl sx={{ m: 1, width: 300 }}>
            <Autocomplete
              multiple
              id="scan-status-autocomplete"
              options={props?.scanStatusList || []}
              getOptionLabel={(option) => option?.desc}
              value={props?.selectedScanStatus || []}
              onChange={handleScanStatusChange}
              renderTags={(tagValue, getTagProps) =>
                tagValue?.map((option, index) => (
                  <Chip
                    key={option?.id}
                    label={option?.desc}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          </FormControl>
        </div>
      </Menu>

      {/* Scan Category */}
      {props?.enableScanCategory && (
        <Button
          color="primary"
          onClick={(event) => handleClick(event, "scanCategory")}
          sx={buttonStyles(props?.selectedScanCategory?.length)}
        >
          Scan Category
          <ArrowDropDownIcon
            sx={{
              fontSize: "20px",
              marginLeft: "3px",
            }}
          />
        </Button>
      )}
      <Menu
        anchorEl={anchorEl?.scanCategory}
        open={Boolean(anchorEl?.scanCategory)}
        onClose={() => handleClose("scanCategory")}
      >
        <div style={{ padding: 10 }}>
          <FormControl sx={{ m: 1, width: 300 }}>
            <Autocomplete
              multiple
              id="scan-status-autocomplete"
              options={scanCategory || []}
              getOptionLabel={(option) => option?.scanCategory || ""}
              value={props?.selectedScanCategory || []}
              onChange={handleScanCategory}
              renderTags={(tagValue, getTagProps) =>
                tagValue?.map((option, index) => (
                  <Chip
                    key={option?.scanCategoryId}
                    label={option?.scanCategory}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          </FormControl>
        </div>
      </Menu>

      {/* Consultant Status Button */}
      {props?.enableConsultantStatus && (
        <Button
          color="primary"
          onClick={(event) => handleClick(event, "consultantScanStatus")}
          sx={buttonStyles(props?.selectedConsultantScanStatus?.length)}
        >
          Status
          <ArrowDropDownIcon
            sx={{
              fontSize: "20px",
              marginLeft: "3px",
            }}
          />
        </Button>
      )}
      <Menu
        anchorEl={anchorEl?.consultantScanStatus}
        open={Boolean(anchorEl?.consultantScanStatus)}
        onClose={() => handleClose("consultantScanStatus")}
      >
        <div style={{ padding: 10 }}>
          <FormControl sx={{ m: 1, width: 300 }}>
            <Autocomplete
              multiple
              id="scan-status-autocomplete"
              options={props?.consultantScanStatusList || []}
              getOptionLabel={(option) => option?.scanStatusDescription}
              value={props?.selectedConsultantScanStatus || []}
              onChange={handleConsultantScanStatus}
              renderTags={(tagValue, getTagProps) =>
                tagValue?.map((option, index) => (
                  <Chip
                    key={option?.scanStatusId}
                    label={option?.scanStatusDescription}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          </FormControl>
        </div>
      </Menu>

      {/* Mode Button */}
      {props?.enableScanModeFilter && (
        <Button
          color="primary"
          onClick={(event) => handleClick(event, "scanMode")}
          sx={buttonStyles(Object?.keys(props?.selectedScanMode)?.length > 0)}
        >
          Mode
          <ArrowDropDownIcon
            sx={{
              fontSize: "20px",
              marginLeft: "3px",
            }}
          />
        </Button>
      )}
      <Menu
        anchorEl={anchorEl?.scanMode}
        open={Boolean(anchorEl?.scanMode)}
        onClose={() => handleClose("scanMode")}
      >
        <div style={{ padding: 10 }}>
          <FormControl sx={{ m: 1, width: 300 }}>
            <Autocomplete
              // multiple
              id="scan-status-autocomplete"
              options={scanMode || []}
              getOptionLabel={(option) => option?.scanModeDesc || ""}
              value={props?.selectedScanMode || []}
              onChange={handleScanModeChange}
              renderTags={(tagValue, getTagProps) =>
                tagValue?.map((option, index) => (
                  <Chip
                    key={option?.scanModeCode}
                    label={option?.scanModeDesc}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          </FormControl>
        </div>
      </Menu>

      {/* Date Range*/}
      {props?.enableDateRangeFilter && (
        <Button
          color="primary"
          onClick={(event) => handleClick(event, "dateFilter")}
          sx={buttonStyles(
            props?.createdOnFilter?.startDate || props?.createdOnFilter?.endDate
            // || props?.publishedOnFilter?.startDate ||
            // props?.publishedOnFilter?.endDate
          )}
        >
          {props.enablePublishedDateFilter
            ? "Publish Date Range"
            : "Date Ranges"}
          <ArrowDropDownIcon
            sx={{
              fontSize: "20px",
              marginLeft: "5px",
            }}
          />
        </Button>
      )}
      <Menu
        anchorEl={anchorEl?.dateFilter}
        open={Boolean(anchorEl?.dateFilter)}
        onClose={() => handleClose("dateFilter")}
      >
        <div style={{ width: 450, padding: 10 }}>
          {" "}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputLabel>
                {props.enablePublishedDateFilter
                  ? "Published on"
                  : "Created On"}
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker", "DatePicker"]}>
                  <DatePicker
                    sx={{ width: "100%" }}
                    label="From Dates"
                    format="DD/MM/YYYY"
                    variant="standard"
                    value={
                      props?.createdOnFilter?.startDate
                        ? dayjs(props?.createdOnFilter?.startDate)
                        : null
                    }
                    onChange={(d) => handleChangeDateValue("startDate", d)}
                  />
                  <DatePicker
                    sx={{ width: "100%" }}
                    label="End Date"
                    format="DD/MM/YYYY"
                    value={
                      props?.createdOnFilter?.endDate
                        ? dayjs(props?.createdOnFilter?.endDate)
                        : null
                    }
                    minDate={dayjs(props?.createdOnFilter?.startDate)}
                    onChange={(d) => handleChangeDateValue("endDate", d)}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <Box
                display="flex"
                justifyContent="flex-end"
                sx={{ marginTop: "-4px", gap: 2 }}
              >
                <Button
                  disabled={
                    !props?.createdOnFilter?.startDate ||
                    !props?.createdOnFilter?.endDate
                      ? true
                      : false
                  }
                  size="small"
                  color="primary"
                  variant="contained"
                  onClick={() => handleReset("dateFilter")}
                  startIcon={<ReplayIcon sx={{ fontSize: "20px" }} />}
                >
                  Reset
                </Button>
              </Box>
            </Grid>
          </Grid>
        </div>
      </Menu>

      {/* Published On Date Range*/}
      {props?.enablePublishOnFilter && (
        <Button
          color="primary"
          onClick={(event) => handleClick(event, "publishedOnFilter")}
          sx={buttonStyles(
            props?.publishOnFilter?.publishedStartDate ||
              props?.publishOnFilter?.publishedEndDate
          )}
        >
          {props.enablePublishedDateFilter ? "Created On" : "Published on"}
          <ArrowDropDownIcon
            sx={{
              fontSize: "20px",
              marginLeft: "5px",
            }}
          />
        </Button>
      )}
      <Menu
        anchorEl={anchorEl?.publishedOnFilter}
        open={Boolean(anchorEl?.publishedOnFilter)}
        onClose={() => handleClose("publishedOnFilter")}
      >
        <div style={{ width: 450, padding: 10 }}>
          {" "}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputLabel>{"Published on"}</InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker", "DatePicker"]}>
                  <DatePicker
                    sx={{ width: "100%" }}
                    label="From Date"
                    format="DD/MM/YYYY"
                    value={
                      props?.publishOnFilter?.publishedStartDate
                        ? dayjs(props?.publishOnFilter?.publishedStartDate)
                        : null
                    }
                    onChange={(d) =>
                      handleChangePublishDateValue("publishedStartDate", d)
                    }
                  />
                  <DatePicker
                    sx={{ width: "100%" }}
                    label="End Date"
                    format="DD/MM/YYYY"
                    value={
                      props?.publishOnFilter?.publishedEndDate
                        ? dayjs(props?.publishOnFilter?.publishedEndDate)
                        : null
                    }
                    minDate={dayjs(props?.publishOnFilter?.publishedStartDate)}
                    onChange={(d) =>
                      handleChangePublishDateValue("publishedEndDate", d)
                    }
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <Box
                display="flex"
                justifyContent="flex-end"
                sx={{ marginTop: "-4px", gap: 2 }}
              >
                <Button
                  disabled={
                    !props?.publishOnFilter?.publishedStartDate ||
                    !props?.publishOnFilter?.publishedEndDate
                      ? true
                      : false
                  }
                  size="small"
                  color="primary"
                  variant="contained"
                  onClick={() => handleReset("publishedOnFilter")}
                  startIcon={<ReplayIcon sx={{ fontSize: "20px" }} />}
                >
                  Reset
                </Button>
              </Box>
            </Grid>
          </Grid>
        </div>
      </Menu>

      {/* Advance Filter */}
      {props?.enableAdvanceFilter && (
        <Button
          color="inherit"
          variant="text"
          onClick={props?.handleAdvanceFilters}
          //   sx={{
          //     ...commonButtonStyles,
          //   }}
        >
          Advance Filter
        </Button>
      )}

      {/* Apply Filter */}
      {props?.enableApplyFilter && props?.isaAvanceFilterVisible && (
        <IconButton
          color="inherit"
          onClick={props?.handleApplyFilters}
          //   sx={{ ...commonButtonStyles }}
          title="Apply Filter"
        >
          <IconFilterCheck
            sx={{
              fontSize: "20px",
              marginLeft: "5px",
            }}
          />
        </IconButton>
      )}
      {/* Clear Filter */}
      {props?.enableClearFilter && props?.isaAvanceFilterVisible && (
        <IconButton
          color="inherit"
          onClick={() => {
            reset();
            props?.handleReset();
          }}
          //   sx={{ ...commonButtonStyles }}
          title="Clear Filter"
        >
          <IconFilterX />
        </IconButton>
      )}
    </div>
  );
};

export default AdvanceGenericScanFilterBar;
