import {
  Stack,
  Grid,
  InputLabel,
  TextField,
  Autocomplete,
  useTheme,
  IconButton,
  Button,
  Tooltip,
  Chip,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { IconFilterCheck, IconFilterX } from "@tabler/icons-react";
import { setIsaAvanceFilterVisible } from "store/slices/clientScanReducer";
import { dispatch } from "store";
import { useSelector } from "react-redux";
import { complianceStatus } from "views/pages/client/scan/constant";

const AdvanceFilterAssetManagementInsight = (props) => {
  const [selectedAssetType, setSelectedAssetType] = useState([]);

  const { scanStatusFilterList, isaAvanceFilterVisible } = useSelector(
    (state) => state?.clientScansState
  );
  const { OrgAssetsList, uploadAsset, assetGroupList, assetGroupManager } =
    useSelector((state) => state?.assetsState);
  const theme = useTheme();
  const [amList, setAmList] = useState([]);

  const commonButtonStyles = {
    padding: "3px 12px",
    fontSize: "0.9rem",
    borderRadius: "7px",
    "&:hover": {
      backgroundColor:
        theme.palette.mode === "dark" ? "white" : theme.palette.primary.light,
    },
    "&:active": {
      backgroundColor:
        theme.palette.mode === "dark" ? "white" : theme.palette.primary.light,
    },
    marginRight: "1px",
    backgroundColor:
      theme.palette.mode === "dark" ? "dark" : theme.palette.primary.light,
    color: theme.palette.mode === "dark" ? "black" : "grey",
    marginTop: "6px",
  };

  const PendingWithArray = [
    { label: "Client", value: "CL", id: 11 },
    { label: "Consultant", value: "CO", id: 22 },
  ];

  const handleScanRefCode = (event, value) => {
    const Value = event.target.value;
    props.setScanFilter((prevFilter) => ({
      ...prevFilter,
      scanRefNo: Value,
    }));
  };

  const handleAssetNameFilter = (event, value) => {
    const Value = event.target.value;
    props.setFilters((prevFilter) => ({
      ...prevFilter,
      assetName: Value,
    }));
  };

  const handleAssetURLFilter = (event, value) => {
    const Value = event.target.value;
    props.setFilters((prevFilter) => ({
      ...prevFilter,
      assetURL: Value,
    }));
  };

  const handleAssetTypes = (event, newValue) => {
    setSelectedAssetType(newValue?.assetSubTypes);
    props.setFilters((prevFilter) => ({
      ...prevFilter,
      assetType: newValue?.assetTypeId,
    }));
  };

  const handleAssetSubTypes = (event, newValue) => {
    props.setFilters((prevFilter) => ({
      ...prevFilter,
      assetSubType: newValue?.assetSubTypeId,
    }));
  };

  useEffect(() => {
    dispatch(setIsaAvanceFilterVisible(false));
  }, []);

  const handlePendingWithChange = (event, newValue, type) => {
    if (newValue === "All") {
      props?.setSelectedPendingOption({
        client: false,
        consultant: false,
      });
    } else if (newValue.label === "Client") {
      props?.setSelectedPendingOption({
        client: true,
        consultant: false,
      });
    } else if (newValue.label === "Consultant") {
      props?.setSelectedPendingOption({
        client: false,
        consultant: true,
      });
    }
  };

  const handleComplianceStatusChange = (event, value) => {
    props?.setSelectedComplianceStatus(value);
  };

  const handleClearFilter = (event, newValue, type) => {
    props?.handleReset();
    props?.handleClose();
    dispatch(setIsaAvanceFilterVisible(true));
  };

  const handleSearchFilter = (event, reason) => {
    // if (reason === "backdropClick") {
    //   dispatch(setIsaAvanceFilterVisible(true));
    // }
    props?.handleApplyFilters();
    props?.handleClose();
    dispatch(setIsaAvanceFilterVisible(true));
  };

  const handleChangeAssetGroupNameList = (event, value) => {
    props?.setSelectedAssetGroupList(value);
  };

  useEffect(() => {
    setAmList(
      assetGroupManager?.length > 0 &&
        assetGroupManager?.map((value, index) => {
          return {
            amId: value?.userId,
            amName: value?.displayName,
          };
        })
    );
  }, [props?.assetGroupManager, assetGroupManager]);

  const handleChangeDateValue = (field, date) => {
    const newDateFilter = { ...props?.dateFilter };
    newDateFilter[field] = date ? date.format("YYYY-MM-DD") : null;
    // If end date is not selected, default it to start date
    if (field === "startDate" && !newDateFilter.endDate && date) {
      newDateFilter.endDate = date.format("YYYY-MM-DD");
    }
    // If start date is not selected, default it to end date
    if (field === "endDate" && !newDateFilter.startDate && date) {
      newDateFilter.startDate = date.format("YYYY-MM-DD");
    }
    props?.setDateFilter(newDateFilter);
  };

  return (
    <>
      <Stack
        direction={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        gap={2}
        mt={2}
        mb={2}
      >
        {props?.enableAssetTypeFilter && (
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={2.5}>
              <InputLabel sx={{ fontSize: "14px", color: "#121926" }}>
                Asset Type
              </InputLabel>
            </Grid>

            <Grid item xs={9.5}>
              <Autocomplete
                options={props?.assetsTypes}
                getOptionLabel={(option) => option?.assetTypeDesc || ""}
                defaultValue={props?.filters?.assetType}
                // value={props?.filters?.assetType} // Match the selected object
                onChange={handleAssetTypes}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    // label="Select Status"
                    variant="standard"
                    fullWidth
                    sx={{
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "#E4E4E4", // Default underline color
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#E4E4E4", // Focused underline color
                      },
                      "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                        borderBottomColor: "#E4E4E4", // Hover underline color
                      },
                      "& .MuiInputBase-input": {
                        color: "black", // Input text color (default value)
                      },
                      "& .MuiInputBase-root": {
                        color: "black", // Text color for dropdown items
                      },
                      "& .MuiSvgIcon-root": {
                        color: "black", // Dropdown icon color
                      },
                      "& .MuiInputLabel-root": {
                        color: "black", // Label color
                      },
                    }}
                  />
                )}
                componentsProps={{
                  paper: {
                    sx: {
                      backgroundColor: "white",
                      "& .MuiAutocomplete-option": {
                        color: "black",
                        "&:hover": {
                          backgroundColor: "#E4E4E4 !important",
                          color: "black",
                        },
                        "&[aria-selected='true']": {
                          backgroundColor: "#E4E4E4 !important",
                          color: "black",
                        },
                      },
                    },
                  },
                }}
              />
            </Grid>
          </Grid>
        )}

        {props?.enableAssetTypeFilter && (
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={2.5}>
              <InputLabel sx={{ fontSize: "14px", color: "#121926" }}>
                Asset Sub-Type
              </InputLabel>
            </Grid>

            <Grid item xs={9.5}>
              <Autocomplete
                disabled={props?.filters?.assetType === ""}
                // options={props?.assetsTypes?.find((item) => item?.assetTypeId === selectedAssetType)?.assetSubTypes || []}
                options={selectedAssetType || []}
                getOptionLabel={(option) => option?.assetSubTypeDesc || ""}
                defaultValue={props?.filters?.assetSubType}
                // value={props?.filters?.assetType} // Match the selected object
                onChange={handleAssetSubTypes}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    // label="Select Status"
                    variant="standard"
                    fullWidth
                    sx={{
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "#E4E4E4", // Default underline color
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#E4E4E4", // Focused underline color
                      },
                      "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                        borderBottomColor: "#E4E4E4", // Hover underline color
                      },
                      "& .MuiInputBase-input": {
                        color: "black", // Input text color (default value)
                      },
                      "& .MuiInputBase-root": {
                        color: "black", // Text color for dropdown items
                      },
                      "& .MuiSvgIcon-root": {
                        color: "black", // Dropdown icon color
                      },
                      "& .MuiInputLabel-root": {
                        color: "black", // Label color
                      },
                    }}
                  />
                )}
                componentsProps={{
                  paper: {
                    sx: {
                      backgroundColor: "white",
                      "& .MuiAutocomplete-option": {
                        color: "black",
                        "&:hover": {
                          backgroundColor: "#E4E4E4 !important",
                          color: "black",
                        },
                        "&[aria-selected='true']": {
                          backgroundColor: "#E4E4E4 !important",
                          color: "black",
                        },
                      },
                    },
                  },
                }}
              />
            </Grid>
          </Grid>
        )}

        {props?.enableAssetgroupFilter && (
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={2.5}>
              <InputLabel sx={{ fontSize: "14px", color: "#121926" }}>
                Asset group
              </InputLabel>
            </Grid>

            <Grid item xs={9.5}>
              <Autocomplete
                multiple
                options={props?.assetGroupMyLists?.data || []}
                getOptionLabel={(option) => option?.groupName}
                value={props?.selectedAssetGroupList || []}
                onChange={handleChangeAssetGroupNameList}
                renderTags={(tagValue, getTagProps) =>
                  tagValue?.map((option, index) => (
                    <Chip
                      key={option?.groupId}
                      label={option?.groupName}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "#E4E4E4", // Default underline color
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#E4E4E4", // Focused underline color
                      },
                      "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                        borderBottomColor: "#E4E4E4", // Hover underline color
                      },
                      "& .MuiInputBase-input": {
                        color: "black", // Input text color (default value)
                      },
                      "& .MuiInputBase-root": {
                        color: "black", // Text color for dropdown items
                      },
                      "& .MuiSvgIcon-root": {
                        color: "black", // Dropdown icon color
                      },
                      "& .MuiInputLabel-root": {
                        color: "black", // Label color
                      },
                    }}
                    variant="standard"
                  />
                )}
                componentsProps={{
                  paper: {
                    sx: {
                      backgroundColor: "white",
                      "& .MuiAutocomplete-option": {
                        color: "black",
                        "&:hover": {
                          backgroundColor: "#E4E4E4 !important",
                          color: "black",
                        },
                        "&[aria-selected='true']": {
                          backgroundColor: "#E4E4E4 !important",
                          color: "black",
                        },
                      },
                    },
                  },
                }}
              />
            </Grid>
          </Grid>
        )}

        {props?.enableAssetManagerAdvanceFilter && (
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={2.5}>
              <InputLabel sx={{ fontSize: "14px", color: "#121926" }}>
                Asset Manager
              </InputLabel>
            </Grid>

            <Grid item xs={9.5}>
              <Autocomplete
                multiple
                // options={props?.assetGroupMyLists?.data || []}
                getOptionLabel={(option) => option?.groupName}
                // disabled={!selectedAssetGroup}
                options={amList || []}
                value={props?.assetGroupFilter?.amId || ""}
                onChange={handleChangeAssetGroupNameList}
                renderTags={(tagValue, getTagProps) =>
                  tagValue?.map((option, index) => (
                    <Chip
                      key={option?.groupId}
                      label={option?.groupName}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "#E4E4E4", // Default underline color
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#E4E4E4", // Focused underline color
                      },
                      "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                        borderBottomColor: "#E4E4E4", // Hover underline color
                      },
                      "& .MuiInputBase-input": {
                        color: "black", // Input text color (default value)
                      },
                      "& .MuiInputBase-root": {
                        color: "black", // Text color for dropdown items
                      },
                      "& .MuiSvgIcon-root": {
                        color: "black", // Dropdown icon color
                      },
                      "& .MuiInputLabel-root": {
                        color: "black", // Label color
                      },
                    }}
                    variant="standard"
                  />
                )}
                componentsProps={{
                  paper: {
                    sx: {
                      backgroundColor: "white",
                      "& .MuiAutocomplete-option": {
                        color: "black",
                        "&:hover": {
                          backgroundColor: "#E4E4E4 !important",
                          color: "black",
                        },
                        "&[aria-selected='true']": {
                          backgroundColor: "#E4E4E4 !important",
                          color: "black",
                        },
                      },
                    },
                  },
                }}
              />
            </Grid>
          </Grid>
        )}

        {props.enableScanRefCodeFilter && (
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={2.5}>
              <InputLabel sx={{ fontSize: "14px", color: "#121926" }}>
                Scan Ref Code
              </InputLabel>
            </Grid>

            <Grid item xs={9.5}>
              <TextField
                variant="standard"
                value={props?.scanFilter?.scanRefNo || ""}
                onChange={handleScanRefCode}
                fullWidth
                disableCloseOnSelect
                sx={{
                  "& .MuiInputBase-input": {
                    color: "black", // Text color
                  },
                  "& .MuiInputLabel-root": {
                    color: "black", // Label color
                  },
                  "& .MuiInput-underline:before": {
                    borderBottomColor: "#E4E4E4", // Default underline color
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "#E4E4E4", // Focused underline color
                  },
                  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                    borderBottomColor: "#E4E4E4", // Hover underline color
                  },
                  "& .MuiInputLabel-root": {
                    color: "black", // Placeholder (label) color
                  },
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="disableCloseOnSelect"
                    variant="standard"
                  />
                )}
              />
            </Grid>
          </Grid>
        )}

        {props.enableAssetNameAdvanceFilter && (
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={2.5}>
              <InputLabel sx={{ fontSize: "14px", color: "#121926" }}>
                Asset Name
              </InputLabel>
            </Grid>

            <Grid item xs={9.5}>
              <TextField
                variant="standard"
                value={props?.filters?.assetName || ""}
                onChange={handleAssetNameFilter}
                fullWidth
                disableCloseOnSelect
                sx={{
                  "& .MuiInputBase-input": {
                    color: "black", // Text color
                  },
                  "& .MuiInputLabel-root": {
                    color: "black", // Label color
                  },
                  "& .MuiInput-underline:before": {
                    borderBottomColor: "#E4E4E4", // Default underline color
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "#E4E4E4", // Focused underline color
                  },
                  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                    borderBottomColor: "#E4E4E4", // Hover underline color
                  },
                  "& .MuiInputLabel-root": {
                    color: "black", // Placeholder (label) color
                  },
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="disableCloseOnSelect"
                    variant="standard"
                  />
                )}
              />
            </Grid>
          </Grid>
        )}

        {props.enableAssetNameAdvanceFilter && (
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={2.5}>
              <InputLabel sx={{ fontSize: "14px", color: "#121926" }}>
                URL/IP Address
              </InputLabel>
            </Grid>

            <Grid item xs={9.5}>
              <TextField
                variant="standard"
                value={props?.filters?.assetURL || ""}
                onChange={handleAssetURLFilter}
                fullWidth
                disableCloseOnSelect
                sx={{
                  "& .MuiInputBase-input": {
                    color: "black", // Text color
                  },
                  "& .MuiInputLabel-root": {
                    color: "black", // Label color
                  },
                  "& .MuiInput-underline:before": {
                    borderBottomColor: "#E4E4E4", // Default underline color
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "#E4E4E4", // Focused underline color
                  },
                  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                    borderBottomColor: "#E4E4E4", // Hover underline color
                  },
                  "& .MuiInputLabel-root": {
                    color: "black", // Placeholder (label) color
                  },
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="disableCloseOnSelect"
                    variant="standard"
                  />
                )}
              />
            </Grid>
          </Grid>
        )}

        {props?.enableComplianceStatusFilter && (
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={2.5}>
              <InputLabel sx={{ fontSize: "14px", color: "#121926" }}>
                Compliance Status
              </InputLabel>
            </Grid>

            <Grid item xs={9.5}>
              <Autocomplete
                options={complianceStatus || []}
                getOptionLabel={(option) => option?.complianceStatusLabel || ""}
                value={props?.selectedComplianceStatus || []}
                onChange={handleComplianceStatusChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    // label="Select Status"
                    variant="standard"
                    fullWidth
                    sx={{
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "#E4E4E4", // Default underline color
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#E4E4E4", // Focused underline color
                      },
                      "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                        borderBottomColor: "#E4E4E4", // Hover underline color
                      },
                      "& .MuiInputBase-input": {
                        color: "black", // Input text color (default value)
                      },
                      "& .MuiInputBase-root": {
                        color: "black", // Text color for dropdown items
                      },
                      "& .MuiSvgIcon-root": {
                        color: "black", // Dropdown icon color
                      },
                      "& .MuiInputLabel-root": {
                        color: "black", // Label color
                      },
                    }}
                  />
                )}
                componentsProps={{
                  paper: {
                    sx: {
                      backgroundColor: "white",
                      "& .MuiAutocomplete-option": {
                        color: "black",
                        "&:hover": {
                          backgroundColor: "#E4E4E4 !important",
                          color: "black",
                        },
                        "&[aria-selected='true']": {
                          backgroundColor: "#E4E4E4 !important",
                          color: "black",
                        },
                      },
                    },
                  },
                }}
              />
            </Grid>
          </Grid>
        )}

        {props?.enablePendingWithAllFilter && (
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={2.5}>
              <InputLabel sx={{ fontSize: "14px", color: "#121926" }}>
                Pending with
              </InputLabel>
            </Grid>

            <Grid item xs={9.5}>
              <Autocomplete
                options={PendingWithArray}
                getOptionLabel={(option) => option?.label}
                value={props?.selectedPendingWith}
                onChange={handlePendingWithChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    // label="Select Status"
                    variant="standard"
                    fullWidth
                    sx={{
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "#E4E4E4", // Default underline color
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#E4E4E4", // Focused underline color
                      },
                      "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                        borderBottomColor: "#E4E4E4", // Hover underline color
                      },
                      "& .MuiInputBase-input": {
                        color: "black", // Input text color (default value)
                      },
                      "& .MuiInputBase-root": {
                        color: "black", // Text color for dropdown items
                      },
                      "& .MuiSvgIcon-root": {
                        color: "black", // Dropdown icon color
                      },
                      "& .MuiInputLabel-root": {
                        color: "black", // Label color
                      },
                    }}
                  />
                )}
                componentsProps={{
                  paper: {
                    sx: {
                      backgroundColor: "white",
                      "& .MuiAutocomplete-option": {
                        color: "black",
                        "&:hover": {
                          backgroundColor: "#E4E4E4 !important",
                          color: "black",
                        },
                        "&[aria-selected='true']": {
                          backgroundColor: "#E4E4E4 !important",
                          color: "black",
                        },
                      },
                    },
                  },
                }}
              />
            </Grid>
          </Grid>
        )}

        {props?.enableCreatedOnFilter && (
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs={2.5}>
              <InputLabel sx={{ fontSize: "14px", color: "#121926" }}>
                Created On
              </InputLabel>
            </Grid>

            <Grid sx={{ overflow: "hidden" }} item xs={9.5}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                  sx={{ overflow: "hidden" }}
                  components={["DatePicker", "DatePicker"]}
                >
                  <DatePicker
                    sx={{
                      width: "100%",
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "#E4E4E4", // Default underline color
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#E4E4E4", // Focused underline color
                      },
                      "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                        borderBottomColor: "#E4E4E4", // Hover underline color
                      },
                      "& .MuiInputBase-input": {
                        color: "black", // Input text color
                      },
                      "& .MuiSvgIcon-root": {
                        color: "black", // Calendar icon color
                      },
                      "& .MuiInputLabel-root": {
                        color: "black", // Placeholder (label) color
                      },
                    }}
                    label="From Date"
                    format="DD/MM/YYYY"
                    value={
                      props?.dateFilter?.startDate
                        ? dayjs(props?.dateFilter?.startDate)
                        : null
                    }
                    onChange={(d) => handleChangeDateValue("startDate", d)}
                    slotProps={{
                      textField: {
                        variant: "standard",
                      },
                      popper: {
                        sx: {
                          "& .MuiPaper-root": {
                            backgroundColor: "white", // Date picker dropdown background color
                            color: "black", // Dropdown text color
                          },
                          "& .MuiButtonBase-root.MuiPickersDay-root": {
                            backgroundColor: "white", // Calendar day background color
                            color: "black", // Calendar day text color
                          },
                          "& .MuiButtonBase-root.MuiPickersDay-root:hover": {
                            backgroundColor: "#E4E4E4", // Hover background color for calendar days
                          },
                        },
                      },
                    }}
                  />
                  <DatePicker
                    sx={{
                      width: "100%",
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "#E4E4E4", // Default underline color
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#E4E4E4", // Focused underline color
                      },
                      "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                        borderBottomColor: "#E4E4E4", // Hover underline color
                      },
                      "& .MuiInputBase-input": {
                        color: "black", // Input text color
                      },
                      "& .MuiSvgIcon-root": {
                        color: "black", // Calendar icon color
                      },
                      "& .MuiInputLabel-root": {
                        color: "black", // Placeholder (label) color
                      },
                    }}
                    label="End Date"
                    format="DD/MM/YYYY"
                    value={
                      props?.dateFilter?.endDate
                        ? dayjs(props?.dateFilter?.endDate)
                        : null
                    }
                    slotProps={{
                      textField: {
                        variant: "standard",
                      },
                      popper: {
                        sx: {
                          "& .MuiPaper-root": {
                            backgroundColor: "white", // Date picker dropdown background color
                            color: "black", // Dropdown text color
                          },
                          "& .MuiButtonBase-root.MuiPickersDay-root": {
                            backgroundColor: "white", // Calendar day background color
                            color: "black", // Calendar day text color
                          },
                          "& .MuiButtonBase-root.MuiPickersDay-root:hover": {
                            backgroundColor: "#E4E4E4", // Hover background color for calendar days
                          },
                        },
                      },
                    }}
                    minDate={dayjs(props?.dateFilter?.startDate)}
                    onChange={(d) => handleChangeDateValue("endDate", d)}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
          </Grid>
        )}
      </Stack>

      <Stack direction={"row"} justifyContent={"flex-end"} gap={2} mb={2}>
        <Tooltip title="Clear Filter" arrow>
          <Button
            // color="secondary"
            onClick={handleClearFilter}
            //   sx={{ ...commonButtonStyles }}
          >
            Clear Filter
          </Button>
        </Tooltip>

        <Tooltip title="Search Filter" arrow>
          <Button
            color="primary"
            variant="contained"
            // onClick={props?.handleClose}
            onClick={handleSearchFilter}
            //   sx={{ ...commonButtonStyles }}
          >
            Search
          </Button>
        </Tooltip>

        {/* <IconButton
                  color="primary"
                  onClick={props?.handleApplyFilters}
                  sx={{
                    ...commonButtonStyles,
                  }}
                  title="Apply Filter"
                >
                  <IconFilterCheck
                    sx={{
                      fontSize: "20px",
                      marginLeft: "5px",
                    }}
                  />
                </IconButton> */}

        {/* Clear Filter */}

        {/* <IconButton
              color="primary"
              onClick={() => {
                // reset();
                props?.handleReset();
              }}
              sx={{
                ...commonButtonStyles,
              }}
              title="Clear Filter"
            >
              <IconFilterX />
            </IconButton> */}
      </Stack>
    </>
  );
};

export default AdvanceFilterAssetManagementInsight;
