import { yupResolver } from "@hookform/resolvers/yup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CheckIcon from "@mui/icons-material/Check";
import ReplayIcon from "@mui/icons-material/Replay";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Grid,
  Menu,
  TextField,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { useTheme } from "@mui/system";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { dispatch } from "store";
// import { fetchClientAssetGroupMyList } from "store/slices/clientAssetsReducer";
// import { fetchDepartmentMyList } from "store/slices/departmentReducer";
import FormControlSelect from "ui-component/extended/Form/FormControlSelect";
import InputLabel from "ui-component/extended/Form/InputLabel";
import { complianceStatus } from "views/pages/client/scan/constant";
import * as yup from "yup";

const validationSchema = yup.object().shape({
  // assetType: yup.string().required('Asset Type is required'),
  // assetSubType: yup.string().required('Asset Sub Type is required'),
});

const GenericFilterBar = (props) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState({
    assetFilter: null,
    consultantStatus: null,
    riskRating: null,
    clientStatus: null,
    dateFilter: null,
    assetManager: null,
    manager: null,
    tester: null,
    assetGroupFilter: null,
    scanFilter: null,
    department: null,
    assetGroupList: null,
    vulReference: null,
    vulnerabilityName: null,
    assetURL: null,
    complianceStatus: null,
    assetName: null,
  });
  const [selectedAssetType, setSelectedAssetType] = useState();
  const [selectedAssetSubType, setSelectedAssetSubType] = useState();
  const [selectedAssetGroup, setSelectedAssetGroup] = useState();
  const [selectedScanType, setSelectedScanType] = useState();
  const [amList, setAmList] = useState([]);
  // const { departmentList , departmentMyList} = useSelector((state) => state?.departmentState);
  // const { assetGroupList, assetGroupMyList } = useSelector((state) => state?.clientAssetsState);
  const { register, watch, setValue, reset } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });
  
  const isAssetSubTypeApplicable = (assetId) => {
    let isApplicable = false;
    props?.assetsTypes?.find((value) => {
      if (value?.assetTypeId === assetId) {
        isApplicable = value?.assetTypeApplicable;
      }
    });
    return isApplicable;
  };

  const handleClick = (event, type) => {
    setAnchorEl((prev) => ({
      ...prev,
      [type]: event?.currentTarget,
    }));
  };
  const handleClose = (name) => {
    if (name === "assetFilter") {
      setAnchorEl({
        assetFilter: null,
      });
    }
    if (name === "consultantStatus") {
      setAnchorEl({
        consultantStatus: null,
      });
    }
    if (name === "clientStatus") {
      setAnchorEl({
        clientStatus: null,
      });
    }
    if (name === "riskRating") {
      setAnchorEl({
        riskRating: null,
      });
    }
    if (name === "dateFilter") {
      setAnchorEl({
        dateFilter: null,
      });
    }
    if (name === "assetGroupFilter") {
      setAnchorEl({
        assetGroupFilter: null,
      });
    }
    if (name === "manager") {
      setAnchorEl({
        manager: null,
      });
    }
    if (name === "tester") {
      setAnchorEl({
        tester: null,
      });
    }
    if (name === "scanFilter") {
      setAnchorEl({
        scanFilter: null,
      });
    }
    if (name === "department") {
      setAnchorEl({
        department: null,
      });
    }
    if (name === "assetGroupList") {
      setAnchorEl({
        assetGroupList: null,
      });
    }
    
    if (name === "vulReference") {
      setAnchorEl({
        vulReference: null,
      });
    }
    if (name === "vulnerabilityName") {
      setAnchorEl({
        vulnerabilityName: null,
      });
    }
    if (name === "assetURL") {
      setAnchorEl({
        assetURL: null,
      });
    }
    if (name === "complianceStatus") {
      setAnchorEl({
        complianceStatus: null,
      });
    }
    if (name === "assetName") {
      setAnchorEl({
        assetName: null,
      });
    }
  };

  useEffect(() => {
    if (props?.enableAssetFilter && props?.assetTypeApi) {
      dispatch(props?.assetTypeApi(props.scanTypeId));
    }
    if (props?.enableConsultantStatusFilter && props?.consultantStatusApi) {
      dispatch(props?.consultantStatusApi());
    }
    if (props?.enableRiskRatingFilter && props?.riskRatingApi) {
      dispatch(props?.riskRatingApi());
    }
    if (props?.enableClientStatusFilter && props?.clientStatusApi) {
      dispatch(props?.clientStatusApi());
    }
    if (props?.enableAssetGroupFilter && props?.fetchAssetGroupList) {
      dispatch(props?.fetchAssetGroupList({ orgId: props.orgId }));
    }
    if (props?.enableScanFilter && props?.scanTypeApi) {
      dispatch(props?.scanTypeApi());
    }
  }, []);

  // useEffect(() => {
  //   if (selectedAssetType) {
  //     dispatch(props?.assetSubTypeApi(selectedAssetType));
  //   }
  // }, [selectedAssetType]);


useEffect(() => {
    if (
      selectedAssetType &&
      selectedAssetSubType &&
      props?.enableAssetListFilter
    ) {
      dispatch(
        props?.getAssetListApi({
          clientOrgId: props?.orgId,
          assetType: selectedAssetType,
          assetSubType: selectedAssetSubType,
          scanId: props.scanId,
        })
      );
    }
  }, [selectedAssetSubType]);

  useEffect(() => {
    if (selectedAssetGroup) {
      dispatch(
        props?.fetchAssetGroupManager({
          assetGroupId: selectedAssetGroup,
          clientOrgId: props.orgId,
        })
      );
    }
  }, [selectedAssetGroup]);

  useEffect(() => {
    watch((value, type) => {
      props?.setFilters({
        ...props?.filters,
        ...value,
      });
      setSelectedAssetType(value?.assetType);
      if (type?.name === "assetType") {
        setValue("assetSubType", "");
        setValue("assetURL", "");
        setValue("assetName", "");
        setValue("assetId", "");
      }
    });
    if (selectedAssetType) {
      setValue("assetSubType", null);
    }
  }, [watch, selectedAssetType]);
  useEffect(() => {
    watch((value, type) => {
      if (type?.name === "assetSubType") {
        props?.setFilters({
          ...props?.filters,
          ...value,
        });
        setSelectedAssetSubType(value?.assetSubType);
      } else if (type?.name === "assetId") {
        props?.setFilters({
          ...props?.filters,
          ...value,
        });
      } else if (type?.name === "scanType") {
        props?.setScanFilter({
          scanType: value?.scanType,
        });
      } else if (type?.name === "assetName") {
        props?.setFilters({
          ...props?.filters,
          ...value,
        });
      } else if (type?.name === "assetURL") {
        props?.setFilters({
          ...props?.filters,
          ...value,
        });
      } else if (type?.name === "vulReference") {
        props.setVulReference(value?.vulReference);
      } else if (type?.name === "vulnerabilityName") {
        props.setVulnerabilityName(value?.vulnerabilityName);
      }
    });
  }, [watch, selectedAssetSubType]);
  useEffect(() => {
    if (props?.setAssetGroupFilter) {
      watch((value) => {
        props?.setAssetGroupFilter({
          ...props?.filters,
          ...value,
        });
        setSelectedAssetGroup(value?.groupId);
      });
      if (selectedAssetGroup) {
        setValue("managerId", 0);
      }
    }
  }, [watch, selectedAssetGroup]);

  useEffect(() => {
    setAmList(
      props?.assetGroupManager?.length > 0 &&
        props?.assetGroupManager?.map((value, index) => {
          return {
            amId: value?.userId,
            amName: value?.displayName,
          };
        })
    );
  }, [props.assetGroupManager]);

  const handleConsultantStatusChange = (event, value) => {
    props?.setSelectedConsultantStatus(value);
  };
  const handleComplianceStatusChange = (event, value) => {
    props?.setSelectedComplianceStatus(value);
  };

  const handleClientStatusChange = (event, value) => {
    props?.setSelectedClientStatus(value);
  };

  const handleRiskRatingChange = (event, value) => {
    props?.setSelectedRiskRating(value);
  };

  const handleClientOrganizationChange = (event, value) => {
    props?.setSelectedClientOrganization(value);
  };

  const handleChangeDateValue = (field, date) => {
    const newDateFilter = { ...props?.dateFilter };
    newDateFilter[field] = date ? date.format("YYYY-MM-DD") : null;
    // If end date is not selected, default it to start date
    if (field === "startDate" && !newDateFilter.endDate && date) {
      newDateFilter.endDate = date.format("YYYY-MM-DD");
    }
    // If start date is not selected, default it to end date
    if (field === "endDate" && !newDateFilter.startDate && date) {
      newDateFilter.startDate = date.format("YYYY-MM-DD");
    }
    props?.setDateFilter(newDateFilter);
  };
  const handleReset = (type) => {
    switch (type) {
      case "dateFilter":
        props?.setDateFilter({ startDate: null, endDate: null });
        break;
      case "vulReference":
        props?.setVulReference("");
        break;
      case "vulnerabilityName":
        props?.setVulnerabilityName("");
        break;
      case "assetFilter":
        setSelectedAssetType("");
        setSelectedAssetSubType("");
        setValue("assetType", "");
        setValue("assetSubType", "");
        setValue("assetId", "");
        setValue("assetName", "");
        break;
      case "assetGroup":
        props?.setAssetGroupFilter({ amId: null, groupid: null });
        break;
        // case "manager":
        //   props?.setAssetGroupFilter({ amId: null, groupid: null });
        //   break;
      case "scanFilter":
        props?.setScanFilter({ scanType: null });
        setValue("scanType", "");
        break;
        case "department":
          props?.setSelectedDepartment("");
          break;
          case "assetGroupList":
            props?.setSelectedAssetGroupList("");
            break;
          
      default:
        break;
    }
  };
  const commonButtonStyles = {
    padding: "5px 12px",
    fontSize: "0.9rem",
    borderRadius: "10px",
    "&:hover": {
      backgroundColor:
        theme.palette.mode === "dark" ? "white" : theme.palette.primary.light,
    },
    "&:active": {
      backgroundColor:
        theme.palette.mode === "dark" ? "white" : theme.palette.primary.light,
    },
    marginRight: "1px",
    backgroundColor:
      theme.palette.mode === "dark" ? "white" : theme.palette.primary.light,
    color: theme.palette.mode === "dark" ? "black" : "darkblue",
    // marginTop: "6px",
  };

  const buttonStyles = (condition) => ({
    padding: "3px 12px",
    fontSize: "0.9rem",
    borderRadius: "7px",
    border: !condition
      ? theme.palette.mode === "dark"
        ? "2px solid white"
        : "2px solid black"
      : "none",
    "&:hover": {
      backgroundColor: !condition
        ? ""
        : theme.palette.mode === "dark"
          ? "white"
          : theme.palette.primary.light,
    },
    "&:active": {
      backgroundColor: condition
        ? ""
        : theme.palette.mode === "dark"
          ? "white"
          : theme.palette.primary.light,
    },
    marginRight: "1px",
    backgroundColor: !condition
      ? ""
      : theme.palette.mode === "dark"
        ? "white"
        : theme.palette.primary.light,
    display: "flex",
    alignItems: "center",
    color: condition
      ? theme.palette.mode === "light"
        ? "darkblue"
        : "black"
      : theme.palette.mode === "dark"
        ? "white"
        : "black",
    // marginTop: "6px",
  });

  const handleChangeDepartmentName = (event, value) => {
    props?.setSelectedDepartment(value);
  };


  const handleChangeAssetGroupNameList = (event, value) => {
    props?.setSelectedAssetGroupList(value);
  };

  const handleChangeManagerName = (event, value) => {
    props?.setSelectedSecurityManagerName(value);
  };

  const handleChangeTesterName = (event, value) => {
    props?.setSelectedTesterName(value);
  };

  const displaySMName = props?.consultantSMList?.length > 0 &&
  props?.consultantSMList?.map((value) => {
    return {
      smName: `${value.firstName} ${value.lastName} (${value.empCode})`,
      smId: value.userId,
    };
  });

  const displayTesterName =
  props?.consultantTesterList?.length > 0 &&
  props?.consultantTesterList?.map((value) => {
    return {
      testerName: `${value.firstName} ${value.lastName} (${value.empCode})`,
      testerId: value.userId,
    };
  });

  return (
    <>
      <>
        {/* Client Organization Filter */}
        {props?.enableClientOrganizationFilter && (
          <Button
            color="primary"
            onClick={(event) => handleClick(event, "clientOrg")}
            sx={buttonStyles(props?.selectedClientOrganization?.length)}
          >
            Client Organization
            <ArrowDropDownIcon
              sx={{
                fontSize: "20px",
                marginLeft: "3px",
              }}
            />
          </Button>
        )}
        <Menu
          anchorEl={anchorEl?.clientOrg}
          open={Boolean(anchorEl?.clientOrg)}
          onClose={() => handleClose("clientOrg")}
        >
          <div style={{ padding: 10 }}>
            <FormControl sx={{ m: 1, width: 300 }}>
              <Autocomplete
                multiple
                // id="scan-status-autocomplete"
                options={props?.clientOrganizationList || []}
                getOptionLabel={(option) => option?.orgName}
                value={props?.selectedClientOrganization || []}
                onChange={handleClientOrganizationChange}
                renderTags={(tagValue, getTagProps) =>
                  tagValue?.map((option, index) => (
                    <Chip
                      key={option?.id}
                      label={option?.orgName}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" />
                )}
              />
            </FormControl>
          </div>
        </Menu>
        {/* Asset Filter Button */}
        {props?.enableAssetFilter && (
          <Button
            color="primary"
            onClick={(event) => handleClick(event, "assetFilter")}
            sx={buttonStyles(
              props?.filters?.assetType ||
                props?.filters?.assetSubType ||
                props?.filters?.assetId
            )}
          >
            Assets
            <ArrowDropDownIcon
              sx={{
                fontSize: "20px",
                marginLeft: "5px",
              }}
            />
          </Button>
        )}
        <Menu
          anchorEl={anchorEl?.assetFilter}
          open={Boolean(anchorEl?.assetFilter)}
          onClose={() => handleClose("assetFilter")}
        >
          <div style={{ width: 450, padding: 10 }}>
            {" "}
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <InputLabel>Asset Type:</InputLabel>
                <FormControlSelect
                  itemsList={props?.assetsTypes}
                  optionLabel={"assetTypeDesc"}
                  optionValue={"assetTypeId"}
                  {...register("assetType")}
                  value={props?.filters?.assetType || ""}
                  name="assetType"
                />
              </Grid>
              <Grid item xs={6}>
                <InputLabel>Asset Sub-Type:</InputLabel>
                <FormControlSelect
                  disabled={!isAssetSubTypeApplicable(selectedAssetType)}
                  itemsList={
                    props?.assetsTypes?.find(
                      (item) => item?.assetTypeId === selectedAssetType
                    )?.assetSubTypes || []
                  }
                  optionLabel={"assetSubTypeDesc"}
                  optionValue={"assetSubTypeId"}
                  {...register("assetSubType")}
                  value={props?.filters?.assetSubType || ""}
                  name="assetSubType"
                />
              </Grid>
              {props?.enableAssetListFilter ? (
                <Grid item xs={12}>
                  <InputLabel>Asset:</InputLabel>
                  <FormControlSelect
                    disabled={
                      selectedAssetType && selectedAssetSubType ? false : true
                    }
                    itemsList={
                      selectedAssetType && selectedAssetSubType
                        ? props?.assetList?.data
                        : []
                    }
                    optionLabel={"assetName"}
                    optionValue={"assetId"}
                    {...register("assetId")}
                    value={props?.filters?.assetId || ""}
                    name="assetId"
                  />
                </Grid>
              ) : (
                ""
              )}
              {/* {props?.enableAssetNameFilter ? (
                <Grid item xs={12}>
                  <InputLabel>Asset Name:</InputLabel>
                  <TextField
                    autoComplete="off"
                    variant="outlined"
                    value={props?.filters?.assetName || ""}
                    name="assetName"
                    {...register("assetName")}
                    fullWidth
                  />
                </Grid>
              ) : (
                ""
              )} */}
              <Grid item xs={12}>
                <Box display="flex" justifyContent="flex-end" sx={{ gap: 2 }}>
                  <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    disabled={!selectedAssetType && !props?.filters?.assetName}
                    onClick={() => handleReset("assetFilter")}
                    startIcon={
                      <ReplayIcon
                        sx={{
                          fontSize: "20px",
                        }}
                      />
                    }
                    sx={{
                      minWidth: 90,
                    }}
                  >
                    Reset
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </div>
        </Menu>


       {/* Manager Filter Button */}
      {props?.enableManagerFilter && (
        <Button
          color="primary"
          onClick={(event) => handleClick(event, "manager")}
          sx={buttonStyles(props?.selectedSecurityManagerName?.length)}
        >
          Manager
          <ArrowDropDownIcon
            sx={{
              fontSize: "20px",
              marginLeft: "3px",
            }}
          />
        </Button>
      )}
      <Menu
        anchorEl={anchorEl?.manager}
        open={Boolean(anchorEl?.manager)}
        onClose={() => handleClose("manager")}
      >
        <div style={{ padding: 10 }}>
          <FormControl sx={{ m: 1, width: 300 }}>
            <Autocomplete
              multiple
              options={displaySMName}
              getOptionLabel={(option) => option?.smName}
              value={props?.selectedSecurityManagerName || []}
              onChange={handleChangeManagerName}
              renderTags={(tagValue, getTagProps) =>
                tagValue?.map((option, index) => (
                  <Chip
                    key={option?.smId}
                    label={option?.smName}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          </FormControl>
        </div>
      </Menu>
      {/* Tester Filter Button */}
      {props?.enableTesterFilter && (
        <Button
          color="primary"
          onClick={(event) => handleClick(event, "tester")}
          sx={buttonStyles(props?.selectedTesterName?.length)}
        >
          Tester
          <ArrowDropDownIcon
            sx={{
              fontSize: "20px",
              marginLeft: "3px",
            }}
          />
        </Button>
      )}
      <Menu
        anchorEl={anchorEl?.tester}
        open={Boolean(anchorEl?.tester)}
        onClose={() => handleClose("tester")}
      >
        <div style={{ padding: 10 }}>
          <FormControl sx={{ m: 1, width: 300 }}>
            <Autocomplete
              multiple
              options={displayTesterName || []}
              getOptionLabel={(option) => option?.testerName}
              value={props?.selectedTesterName || []}
              onChange={handleChangeTesterName}
              renderTags={(tagValue, getTagProps) =>
                tagValue?.map((option, index) => (
                  <Chip
                    key={option?.testerId}
                    label={option?.testerName}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
              // sx={{
              //   "& .MuiAutocomplete-listbox": {
              //     maxHeight: "200px", // Set the maximum height of the dropdown
              //     overflow: "auto", // Add scroll bar if needed
              //   },
              // }}
              // PaperComponent={(props) => (
              //   <Paper
              //     {...props}
              //     style={{ maxHeight: 200, overflow: "auto" }}
              //   />
              // )}
            />
          </FormControl>
        </div>
      </Menu>

 {/* Manager department Button */}
 {props?.enableDepartmentFilter && (
        <Button
          color="primary"
          onClick={(event) => handleClick(event, "department")}
          sx={buttonStyles(props?.selectedDepartment?.length)}
        >
          Department
          <ArrowDropDownIcon
            sx={{
              fontSize: "20px",
              marginLeft: "3px",
            }}
          />
        </Button>
      )}
      <Menu
        anchorEl={anchorEl?.department}
        open={Boolean(anchorEl?.department)}
        onClose={() => handleClose("department")}
      >
        <div style={{ padding: 10 }}>
          <FormControl sx={{ m: 1, width: 300 }}>
            <Autocomplete
              multiple
              options={props?.departmentMyLists || []}
              getOptionLabel={(option) => option?.departmentName}
              value={props?.selectedDepartment || []}
              onChange={handleChangeDepartmentName}
              renderTags={(tagValue, getTagProps) =>
                tagValue?.map((option, index) => (
                  <Chip
                    key={option?.dmId}
                    label={option?.departmentName}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          </FormControl>
        </div>
      </Menu>


       {/* Asset group list filter */}
 {props?.enableAssetgroupFilter && (
        <Button
          color="primary"
          onClick={(event) => handleClick(event, "assetGroupList")}
          sx={buttonStyles(props?.selectedAssetGroupList?.length)}
        >
          Asset group
          <ArrowDropDownIcon
            sx={{
              fontSize: "20px",
              marginLeft: "3px",
            }}
          />
        </Button>
      )}
      <Menu
        anchorEl={anchorEl?.assetGroupList}
        open={Boolean(anchorEl?.assetGroupList)}
        onClose={() => handleClose("assetGroupList")}
      >
        <div style={{ padding: 10 }}>
          <FormControl sx={{ m: 1, width: 300 }}>
            <Autocomplete
              multiple
              options={props?.assetGroupMyLists?.data || []}
              getOptionLabel={(option) => option?.groupName}
              value={props?.selectedAssetGroupList || []}
              onChange={handleChangeAssetGroupNameList}
              renderTags={(tagValue, getTagProps) =>
                tagValue?.map((option, index) => (
                  <Chip
                    key={option?.groupId}
                    label={option?.groupName}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          </FormControl>
        </div>
      </Menu>

        {props?.enableScanFilter && (
          <Button
            color="primary"
            onClick={(event) => handleClick(event, "scanFilter")}
            sx={buttonStyles(
              props?.scanFilter?.scanName ||
                props?.scanFilter?.scanRefNo ||
                props?.scanFilter?.scanSubType ||
                props?.scanFilter?.scanType
            )}
          >
            Scan
            <ArrowDropDownIcon
              sx={{
                fontSize: "20px",
                marginLeft: "5px",
              }}
            />
          </Button>
        )}
        <Menu
          anchorEl={anchorEl?.scanFilter}
          open={Boolean(anchorEl?.scanFilter)}
          onClose={() => handleClose("scanFilter")}
        >
          <div style={{ width: 550, padding: 15 }}>
            {" "}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <InputLabel>Type:</InputLabel>
                <FormControlSelect
                  itemsList={props?.scanTypes}
                  optionLabel={"scanTypeDescription"}
                  optionValue={"scanTypeId"}
                  {...register("scanType")}
                  value={props?.scanFilter?.scanType || ""}
                  name="scanType"
                />
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="flex-end" sx={{ gap: 2 }}>
                  <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    onClick={() => handleReset("scanFilter")}
                    startIcon={
                      <ReplayIcon
                        sx={{
                          fontSize: "20px",
                        }}
                      />
                    }
                    sx={{
                      minWidth: 90,
                    }}
                  >
                    Reset
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </div>
        </Menu>
        {/* Risk Rating Status Filter */}
        {props?.enableRiskRatingFilter && (
          <Button
            color="primary"
            onClick={(event) => handleClick(event, "riskRating")}
            sx={buttonStyles(props?.selectedRiskRating?.length)}
          >
            Risk Rating
            <ArrowDropDownIcon
              sx={{
                fontSize: "20px",
                marginLeft: "5px",
              }}
            />
          </Button>
        )}
        <Menu
          anchorEl={anchorEl?.riskRating}
          open={Boolean(anchorEl?.riskRating)}
          onClose={() => handleClose("riskRating")}
        >
          <div style={{ padding: 10 }}>
            <FormControl sx={{ m: 1, width: 300 }}>
              <Autocomplete
                multiple
                id="client-status-autocomplete"
                options={props?.riskRatingValue || []}
                getOptionLabel={(option) => option?.riskRatingDesc}
                value={props?.selectedRiskRating || []}
                onChange={handleRiskRatingChange}
                renderTags={(tagValue, getTagProps) =>
                  tagValue?.map((option, index) => (
                    <Chip
                      key={option?.riskRatingCode}
                      label={option?.riskRatingDesc}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    // label=" "
                  />
                )}
              />
            </FormControl>
          </div>
        </Menu>
        {/* Client Status Filter */}
        {props?.enableClientStatusFilter && (
          <Button
            color="primary"
            onClick={(event) => handleClick(event, "clientStatus")}
            sx={buttonStyles(props?.selectedClientStatus?.length)}
          >
            Client Status
            <ArrowDropDownIcon
              sx={{
                fontSize: "20px",
                marginLeft: "5px",
              }}
            />
          </Button>
        )}
        <Menu
          anchorEl={anchorEl?.clientStatus}
          open={Boolean(anchorEl?.clientStatus)}
          onClose={() => handleClose("clientStatus")}
        >
          <div style={{ padding: 10 }}>
            <FormControl sx={{ m: 1, width: 300 }}>
              <Autocomplete
                multiple
                id="client-status-autocomplete"
                options={props?.clientStatusValue || []}
                getOptionLabel={(option) => option?.vulStatusDesc}
                value={props?.selectedClientStatus || []}
                onChange={handleClientStatusChange}
                renderTags={(tagValue, getTagProps) =>
                  tagValue?.map((option, index) => (
                    <Chip
                      key={option?.vulStatusId}
                      label={option?.vulStatusDesc}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    // label=" "
                  />
                )}
              />
            </FormControl>
          </div>
        </Menu>
        {props?.enableVulReferenceFilter && (
          <Button
            color="primary"
            onClick={(event) => handleClick(event, "vulReference")}
            sx={buttonStyles(
              props?.scanFilter?.scanName ||
                props?.scanFilter?.scanRefNo ||
                props?.scanFilter?.scanSubType ||
                props?.scanFilter?.scanType ||
                props?.vulReference
            )}
          >
            Vulnerability Referance
            <ArrowDropDownIcon
              sx={{
                fontSize: "20px",
                marginLeft: "5px",
              }}
            />
          </Button>
        )}
        <Menu
          anchorEl={anchorEl?.vulReference}
          open={Boolean(anchorEl?.vulReference)}
          onClose={() => handleClose("vulReference")}
        >
          <div style={{ width: 550, padding: 15 }}>
            <Grid item xs={6}>
              <InputLabel>Vulnerability Ref Code</InputLabel>
              <TextField
                autoComplete="off"
                variant="outlined"
                value={props?.vulReference || ""}
                name="vulReference"
                {...register("vulReference")}
                fullWidth
              />
            </Grid>
            {/* <Grid item xs={12}>
              <Box display="flex" justifyContent="flex-end" sx={{ gap: 2 }}>
                <Button
                  size="small"
                  color="primary"
                  variant="contained"
                  onClick={() => handleReset("vulReference")}
                  startIcon={
                    <ReplayIcon
                      sx={{
                        fontSize: "20px",
                      }}
                    />
                  }
                  sx={{
                    minWidth: 90,
                  }}
                >
                  Reset
                </Button>
              </Box>
            </Grid> */}
            {/* </Grid> */}
          </div>
        </Menu>
        {props?.enableVulnerabilityNameFilter && (
          <Button
            color="primary"
            onClick={(event) => handleClick(event, "vulnerabilityName")}
            sx={buttonStyles(
              props?.scanFilter?.scanName ||
                props?.scanFilter?.scanRefNo ||
                props?.scanFilter?.scanSubType ||
                props?.scanFilter?.scanType ||
                props?.vulnerabilityName
            )}
          >
            Vulnerability Name
            <ArrowDropDownIcon
              sx={{
                fontSize: "20px",
                marginLeft: "5px",
              }}
            />
          </Button>
        )}
        <Menu
          anchorEl={anchorEl?.vulnerabilityName}
          open={Boolean(anchorEl?.vulnerabilityName)}
          onClose={() => handleClose("vulnerabilityName")}
        >
          <div style={{ width: 550, padding: 15 }}>
            <Grid item xs={12}>
              <InputLabel>Vulnerability Name</InputLabel>
              <TextField
                autoComplete="off"
                variant="outlined"
                value={props?.vulnerabilityName || ""}
                name="vulnerabilityName"
                {...register("vulnerabilityName")}
                fullWidth
              />
            </Grid>
            {/* <Grid item xs={12}>
              <Box display="flex" justifyContent="flex-end" sx={{ gap: 2 }}>
                <Button
                  size="small"
                  color="primary"
                  variant="contained"
                  onClick={() => handleReset("vulReference")}
                  startIcon={
                    <ReplayIcon
                      sx={{
                        fontSize: "20px",
                      }}
                    />
                  }
                  sx={{
                    minWidth: 90,
                  }}
                >
                  Reset
                </Button>
              </Box>
            </Grid> */}
            {/* </Grid> */}
          </div>
        </Menu>
        {props?.enableUrlIpAddressFilter && (
          <Button
            color="primary"
            onClick={(event) => handleClick(event, "assetURL")}
            sx={buttonStyles(props?.filters?.assetURL)}
          >
            URL/IP Address
            <ArrowDropDownIcon
              sx={{
                fontSize: "20px",
                marginLeft: "5px",
              }}
            />
          </Button>
        )}
        <Menu
          anchorEl={anchorEl?.assetURL}
          open={Boolean(anchorEl?.assetURL)}
          onClose={() => handleClose("assetURL")}
        >
          <div style={{ width: 550, padding: 15 }}>
            <Grid item xs={12}>
              <InputLabel>URL / IP address:</InputLabel>
              <TextField
                autoComplete="off"
                variant="outlined"
                value={props?.filters?.assetURL || ""}
                name="assetURL"
                {...register("assetURL")}
                fullWidth
                placeholder="eg: IpAdrees1,IpAdrees1"
              />
            </Grid>
          </div>
        </Menu>
        {props?.enableAssetNameFilter && (
          <Button
            color="primary"
            onClick={(event) => handleClick(event, "assetName")}
            sx={buttonStyles(props?.filters?.assetName)}
          >
            Asset Name
            <ArrowDropDownIcon
              sx={{
                fontSize: "20px",
                marginLeft: "5px",
              }}
            />
          </Button>
        )}
        <Menu
          anchorEl={anchorEl?.assetName}
          open={Boolean(anchorEl?.assetName)}
          onClose={() => handleClose("assetName")}
        >
          <div style={{ width: 550, padding: 15 }}>
            <Grid item xs={12}>
              <InputLabel>Asset Name: </InputLabel>
              <TextField
                autoComplete="off"
                variant="outlined"
                value={props?.filters?.assetName || ""}
                name="assetName"
                {...register("assetName")}
                fullWidth
                placeholder="Enter Asset Name"
              />
            </Grid>
          </div>
        </Menu>
        {/* Consultant Status Filter */}
        {props?.enableConsultantStatusFilter && (
          <Button
            // variant="contained"
            color="primary"
            onClick={(event) => handleClick(event, "consultantStatus")}
            sx={buttonStyles(props?.selectedConsultantStatus?.length)}
          >
            Consultant Status
            <ArrowDropDownIcon
              sx={{
                fontSize: "20px",
                marginLeft: "5px",
              }}
            />
          </Button>
        )}
        <Menu
          anchorEl={anchorEl?.consultantStatus}
          open={Boolean(anchorEl?.consultantStatus)}
          onClose={() => handleClose("consultantStatus")}
        >
          <div style={{ padding: 10 }}>
            <FormControl sx={{ m: 1, width: 300 }}>
              <Autocomplete
                multiple
                id="consultant-status-autocomplete"
                options={props?.consultantStatusValue || []}
                getOptionLabel={(option) => option?.vulStatusDesc}
                value={props?.selectedConsultantStatus || []}
                onChange={handleConsultantStatusChange}
                renderTags={(tagValue, getTagProps) =>
                  tagValue?.map((option, index) => (
                    <Chip
                      key={option?.vulStatusId}
                      label={option?.vulStatusDesc}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    // label=" "
                  />
                )}
              />
            </FormControl>
          </div>
        </Menu>

        {/* Complaince Status Filter */}
        { props?.enableComplianceStatusFilter && (
          <Button
            // variant="contained"
            color="primary"
            onClick={(event) => handleClick(event, "complianceStatus")}
            sx={buttonStyles(props?.selectedComplianceStatus?.complianceStatusCode  )}
          >
            Compliance Status
            <ArrowDropDownIcon
              sx={{
                fontSize: "20px",
                marginLeft: "5px",
              }}
            />
          </Button>
        )}
        <Menu
          anchorEl={anchorEl?.complianceStatus}
          open={Boolean(anchorEl?.complianceStatus)}
          onClose={() => handleClose("complianceStatus")}
        >
          <div style={{ padding: 10 }}>
            <FormControl sx={{ m: 1, width: 300 }}>
              <Autocomplete
                id="compliance-status-autocomplete"
                options={complianceStatus || []}
                getOptionLabel={(option) => option?.complianceStatusLabel || ""}
                value={props?.selectedComplianceStatus || []}
                onChange={handleComplianceStatusChange}
                renderTags={(tagValue, getTagProps) =>
                  tagValue?.map((option, index) => (
                    <Chip
                      key={option?.complianceStatusCode}
                      label={option?.complianceStatusLabel}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    // label=" "
                  />
                )}
              />
            </FormControl>
          </div>
        </Menu>
        {props?.enableAssetGroupFilter && (
          <Button
            color="primary"
            onClick={(event) => handleClick(event, "assetGroupFilter")}
            sx={buttonStyles(props?.assetGroupFilter?.groupId)}
          >
            Assets Group
            <ArrowDropDownIcon
              sx={{
                fontSize: "20px",
                marginLeft: "5px",
              }}
            />
          </Button>
        )}
        <Menu
          anchorEl={anchorEl?.assetGroupFilter}
          open={Boolean(anchorEl?.assetGroupFilter)}
          onClose={() => handleClose("assetGroupFilter")}
        >
          <div style={{ width: 450, padding: 10 }}>
            {" "}
            <Grid container spacing={2}>
              <Grid item xs={props.enableAssetManagerFilter ? 6 : 12}>
                <InputLabel>Asset Group :</InputLabel>
                <FormControlSelect
                  itemsList={props?.assetGroupList?.data}
                  optionLabel={"groupName"}
                  optionValue={"groupId"}
                  {...register("groupId")}
                  value={props?.assetGroupFilter?.groupId || ""}
                  name="groupId"
                />
              </Grid>
              {props.enableAssetManagerFilter && (
                <Grid item xs={6}>
                  <InputLabel>Asset Manager:</InputLabel>
                  <FormControlSelect
                    disabled={!selectedAssetGroup}
                    itemsList={amList || []}
                    optionLabel={"amName"}
                    optionValue={"amId"}
                    {...register("amId")}
                    value={props?.assetGroupFilter?.amId || ""}
                    name="amId"
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <Box display="flex" justifyContent="flex-end" sx={{ gap: 2 }}>
                  <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    disabled={!selectedAssetGroup}
                    onClick={() => handleReset("assetGroup")}
                    startIcon={
                      <ReplayIcon
                        sx={{
                          fontSize: "20px",
                        }}
                      />
                    }
                    sx={{
                      minWidth: 90,
                    }}
                  >
                    Reset
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </div>
        </Menu>
        {/* Reported On Filter */}
        {props?.enableReportedOnFilter && (
          <Button
            color="primary"
            onClick={(event) => handleClick(event, "dateFilter")}
            sx={buttonStyles(
              props?.dateFilter?.startDate || props?.dateFilter?.endDate
            )}
          >
            {props?.enablePublishedDateFilter ? "Published On" : "Reported On"}
            <ArrowDropDownIcon
              sx={{
                fontSize: "20px",
                marginLeft: "5px",
              }}
            />
          </Button>
        )}
        <Menu
          anchorEl={anchorEl?.dateFilter}
          open={Boolean(anchorEl?.dateFilter)}
          onClose={() => handleClose("dateFilter")}
        >
          <div style={{ width: 450, padding: 10 }}>
            {" "}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker", "DatePicker"]}>
                    <DatePicker
                      sx={{ width: "100%" }}
                      label="From Date"
                      format="DD/MM/YYYY"
                      value={
                        props?.dateFilter?.startDate
                          ? dayjs(props?.dateFilter?.startDate)
                          : null
                      }
                      onChange={(d) => handleChangeDateValue("startDate", d)}
                    />
                    <DatePicker
                      sx={{ width: "100%" }}
                      label="End Date"
                      format="DD/MM/YYYY"
                      value={
                        props?.dateFilter?.endDate
                          ? dayjs(props?.dateFilter?.endDate)
                          : null
                      }
                      minDate={dayjs(props?.dateFilter?.startDate)}
                      onChange={(d) => handleChangeDateValue("endDate", d)}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box
                display="flex"
                justifyContent="flex-end"
                sx={{ marginTop: "10px", gap: 2 }}
              >
                <Button
                  disabled={
                    !(
                      props?.dateFilter?.startDate || props?.dateFilter?.endDate
                    )
                  }
                  size="small"
                  color="primary"
                  variant="contained"
                  onClick={() => handleReset("dateFilter")}
                  startIcon={
                    <ReplayIcon
                      sx={{
                        fontSize: "20px",
                      }}
                    />
                  }
                >
                  Reset
                </Button>
              </Box>
            </Grid>
          </div>
        </Menu>
        {/* Apply Button */}
        {props?.enableApplyFilter && (
          <Button
            color="primary"
            onClick={props?.handleApplyFilters}
            sx={{
              ...commonButtonStyles,
            }}
          >
            Apply
            <CheckIcon
              sx={{
                fontSize: "20px",
                marginLeft: "5px",
              }}
            />
          </Button>
        )}
        {/* Clear Filter */}
        {props?.enableClearFilter && (
          <Button
            color="primary"
            onClick={() => {
              reset();
              props?.handleReset();
            }}
            sx={{
              ...commonButtonStyles,
            }}
          >
            Clear All Filters
            <ReplayIcon
              sx={{
                fontSize: "20px",
              }}
            />
          </Button>
        )}
      </>
    </>
  );
};

export default GenericFilterBar;
