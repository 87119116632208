import AddCommentIcon from '@mui/icons-material/AddComment';
import { IconButton, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { dispatch } from 'store';
import { addScanComment, deleteScanComment, getScanComments, uploadNewScanComment } from 'store/slices/scanReducer';
import MainCard from 'ui-component/cards/MainCard';
import Delete from 'ui-component/delete';
import DialogBox from 'ui-component/dialogbox';
import AddComments from 'views/Common/addComment';
import Comment from './comment';

const ScanComment = (props) => {
  const theme = useTheme();
  const params = useParams();
  const [open, setOpen] = useState();
  const [selectedImgDetail, setSelectedImgDetail] = useState();
  const [openImage, setOpenImage] = useState(false);
  const [deleteModelOpen, setDeleteModelOpen] = useState();
  const [scanCommentsList, setScanCommentsList] = useState([]);
  const [selectedCommentId, setSelectedCommentId] = useState();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const { scanComments } = useSelector((state) => state?.scansState);
  const { addScanCommentResponse } = useSelector((state) => state?.scansState);
  useEffect(() => {
    setScanCommentsList(scanComments);
  }, [scanComments]);
  useEffect(() => {
    setScanCommentsList([...scanCommentsList, addScanCommentResponse]);
  }, [addScanCommentResponse]);
  useEffect(() => {
    // if (userActionAccess(ACTION_TYPE?.GET_SCAN_CMT_LIST)) {
      dispatch(getScanComments(params?.scanId, props?.scanDetails.clientOrgId));
    // }
  }, [params?.scanId]);
  const handleCommentsModel = () => {
    setOpen(!open);
  };

  const handleCloseDialog = () => {
    setOpenImage(false);
  };
  const handleSubmitComment = (comment) => {
    const payload = {
      scanId: parseInt(params?.scanId),
      isLocked: true,
      clientDisplay: true,
      commentDesc: comment,
      createdOn: moment().toISOString(),
      createdBy: 1,
      clientOrgId: props?.scanDetails.clientOrgId,
    };
    dispatch(addScanComment(payload)).then((res) => {
      const resData = [];
      if (uploadedFiles?.length) {
        uploadedFiles?.map((item) => {
          const formData = new FormData();
          formData.append('file', item.file);
          if (res?.scanCommentId) {
            const payload = {
              commentId: res?.scanCommentId,
              clientOrgId: props?.scanDetails.clientOrgId,
              file: formData,
            };
            dispatch(uploadNewScanComment(payload)).then((res) => {
              resData.push(res?.data);
              if (uploadedFiles?.length === resData?.length) {
                // if (userActionAccess(ACTION_TYPE?.GET_SCAN_CMT_LIST)) {
                  dispatch(getScanComments(params?.scanId, props?.scanDetails.clientOrgId));
                // }
                handleCommentsModel();
              }
            });
          }
        });
      } else {
        // if (userActionAccess(ACTION_TYPE?.GET_SCAN_CMT_LIST)) {
          dispatch(getScanComments(params?.scanId, props?.scanDetails.clientOrgId));
        // }
      }
    });
  };
  const handleManageDeleteModel = () => {
    setDeleteModelOpen(!deleteModelOpen);
  };
  const handleCommentDeleteAction = () => {
    dispatch(deleteScanComment(selectedCommentId));
    setTimeout(() => {
      // if (userActionAccess(ACTION_TYPE?.GET_SCAN_CMT_LIST)) {
        dispatch(getScanComments(params?.scanId, props?.scanDetails.clientOrgId));
      // }
    }, 200);
    handleManageDeleteModel();
  };

  return (
    <MainCard
      sx={{
        background: theme.palette.mode === 'dark' ? theme.palette.background.paper : theme.palette.grey[50],
      }}
    >
      <Stack
        mb={3}
        justifyContent={'flex-end'}
        width={'100%'}
        direction={'row'}
      >
        {props?.scanDetails?.displayScanVulnerabilityAction
          ?.showAddScanComment ? (
          <IconButton
            title="Comment"
            variant="contained"
            color="inherit"
            size="large"
            aria-label="live customize"
            onClick={handleCommentsModel}
          >
            <AddCommentIcon />
          </IconButton>
        ) : (
          <></>
        )}
      </Stack>
      {scanCommentsList && scanCommentsList?.length ? (
        <>
          {scanCommentsList[0].scanCommentId &&
            scanCommentsList?.map((item) => (
              <>
                {item.scanCommentId && (
                  <Comment
                    item={item}
                    handleManageDeleteModel={handleManageDeleteModel}
                    setSelectedCommentId={setSelectedCommentId}
                  />
                )}
              </>
            ))}
        </>
      ) : (
        <></>
      )}
      <DialogBox
        maxWidth="md"
        modelTitle={'Add Comments'}
        open={open}
        handleClose={handleCommentsModel}
        dialogContent={
          <AddComments
            setUploadedFiles={setUploadedFiles}
            uploadedFiles={uploadedFiles}
            handleCommentsModel={handleCommentsModel}
            handleSubmitComment={handleSubmitComment}
          />
        }
      />
      <DialogBox
        maxWidth="sm"
        modelTitle={'Delete Comment'}
        open={deleteModelOpen}
        handleClose={handleManageDeleteModel}
        dialogContent={
          <Delete
            handleDeleteAction={handleCommentDeleteAction}
            handleClose={handleManageDeleteModel}
          />
        }
      />
    </MainCard>
  );
};

export default ScanComment;
