// third-party
import { createSlice } from "@reduxjs/toolkit";

// project imports
import { notificationManager } from "ui-component/notificationManager";
import axios from "utils/axios";
import { dispatch } from "../index";
import { openSnackbar } from "./snackbarReducer";
import { setLoading } from "./user";

const clientScanApiEndpoint = "/client/v1/scan";
const clientReportsApiEndpoint = "/client/v1/reports";
const clientVulnerabilityApiEndpoint = "/client/v1/vulnerability";
const consultantEndpointApi = "/consultant/v1";
const consultantVulnerabilityApiEndpoint = "/consultant/v1/vulnerability";
const clientEvidenceApiEndpoint = "/client/v1/vul-evidence";

const initialState = {
  error: null,
  consultantOrganizationList: [],
  deleteScanResponse: [],
  publishedVulnerabilityList: {},
  selectedPublishedVulnerability: {},
  rescanSavedVulnerabilityList: [],
  vulnerabilityList: [],
  rescanVulnerabilities: {},
  rescanVulnerabilityList: [],
  deleteRescanSavedVulnerabilityResponse: [],
  updateSubmitScanToConsultant: [],
  publishedVulnerabilityEvidenceList: [],
  downloadSelectedVulnerabilityEvidence: {},
  selectedRescanVulnerability: {},
  updateRescanVulnerabilityStatus: {},
  AgeBuckets: [],
  VulnerabilitiesExportResponse: {},
  scanStatusFilterList: [],
  exportPushlishedVulnerabilityRes: {},
  isaAvanceFilterVisible: true,
};

const slice = createSlice({
  name: "client-scans",
  initialState,
  reducers: {
    setIsaAvanceFilterVisible(state, action) {
      state.isaAvanceFilterVisible = action.payload;
    },
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // --------------------- GET APIs --------------------------------
    //GET CONSULTANT_ORGANIZATION_LIST
    getConsultantOrganizationListSuccess(state, action) {
      state.consultantOrganizationList = action.payload;
    },
    //GET PUBLISHED VUL LIST
    getPublishedVulnerabilityListSuccess(state, action) {
      state.publishedVulnerabilityList = action.payload;
    },
    // GET SELETED PUBLISHED VUL DETAILS
    getSelectedPublishedVulnerabilitySuccess(state, action) {
      state.selectedPublishedVulnerability = action.payload;
    },
    // GET RESCAN SAVED VUL LIST
    getRescanSavedVulnerabilityListSuccess(state, action) {
      state.rescanSavedVulnerabilityList = action.payload;
    },
    // GET VUL LIST
    getVulnerabilitiesListSuccess(state, action) {
      state.vulnerabilityList = action.payload;
    },
    // GET PUBLISHED VUL EVIDENCE LIST
    getPublishedVulnerabilityEvidenceListSuccess(state, action) {
      state.publishedVulnerabilityEvidenceList = action.payload;
    },
    //DOWNLOAD SELETED PUBLISHED VUL EVIDENCE
    getDownloadSelectedPublishedVulEvidenceSuccess(state, action) {
      state.downloadSelectedVulnerabilityEvidence = action.payload;
    },
    // GET SELECTED RESCAN VUL DETAILS
    getSelectedRescanVulnerabiitySuccess(state, action) {
      state.selectedRescanVulnerability = action.payload;
    },

    fetchAgeBucketsSuccess(state, action) {
      state.AgeBuckets = action.payload;
    },
    VulnerabilitiesExportSuccess(state, action) {
      state.VulnerabilitiesExportResponse = action.payload;
    },
    //GET SCAN STATUS LIST
    getScanStatusFilterListSuccess(state, action) {
      state.scanStatusFilterList = action.payload;
    },
    //GET EXPORT PUBLISHED VULNERABILITY
    exportPushlishedVulnerabilitySuccess(state, action) {
      state.exportPushlishedVulnerabilityRes = action.payload;
    },
    // --------------------- DELETE APIs -----------------------------

    // DELETE SCAN
    deleteScanSuccess(state, action) {
      state.deleteScanResponse = action.payload;
    },

    //DELETE RESCAN VULNERABILITY
    deleteRescanSavedVulnerabilitySuccess(state, action) {
      state.deleteRescanSavedVulnerabilityResponse = action.payload;
    },

    // --------------------- POST APIs -----------------------------

    //RESCAN VULNERABILITIES
    addRescanVulnerabilitiesSuccess(state, action) {
      state.rescanVulnerabilities = action.payload;
    },

    // --------------------- PUT APIs -----------------------------
    updateSubmitScanToConsultantSuccess(state, action) {
      state.updateSubmitScanToConsultant = action.payload;
    },
    updateRescanVulnerabilityStatusSuccess(state, action) {
      state.updateRescanVulnerabilityStatus = action.payload;
    },
  },
});

export default slice.reducer;
export const {
  VulnerabilitiesExportSuccess,
  setIsaAvanceFilterVisible,
  exportPushlishedVulnerabilitySuccess,
} = slice.actions;

// ----------------------------------------------------------------------

export const getConsultantOrganizationList = () => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(
        `${clientScanApiEndpoint}/consultant-org/list`
      );
      dispatch(
        slice.actions.getConsultantOrganizationListSuccess(response.data)
      );
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const deleteScan = (payload) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.delete(`${clientScanApiEndpoint}`, {
        data: payload,
      });
      dispatch(slice.actions.deleteScanSuccess(response.data));
      return response;
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getPublishedVulnerabilityList = (params) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(
        `${clientVulnerabilityApiEndpoint}/published/${params?.scanId}`,
        {
          params,
        }
      );
      dispatch(
        slice.actions.getPublishedVulnerabilityListSuccess(response.data)
      );
      dispatch(slice.actions.getSelectedPublishedVulnerabilitySuccess({}));
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getSelectedPublishedVulnerability = (svId) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(
        `${clientVulnerabilityApiEndpoint}/scan-vul/${svId}`
      );
      dispatch(
        slice.actions.getSelectedPublishedVulnerabilitySuccess(response.data)
      );
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getRescanSavedVulnerabilityList = (params) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(
        `${clientVulnerabilityApiEndpoint}/saved-list/${params?.scanId}`,
        {
          params,
        }
      );
      dispatch(
        slice.actions.getRescanSavedVulnerabilityListSuccess(response.data)
      );
      dispatch(slice.actions.getSelectedRescanVulnerabiitySuccess({}));
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const addRescanVulnerabilities = (scanId, payload) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.post(
        `${clientVulnerabilityApiEndpoint}/add/${scanId}`,
        payload
      );
      dispatch(slice.actions.addRescanVulnerabilitiesSuccess(response.data));
      notificationManager(response);
      return response;
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getVulnerabilityList = (params) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(
        `${clientVulnerabilityApiEndpoint}/list/${params.scanId}`,
        { params }
      );
      dispatch(slice.actions.getVulnerabilitiesListSuccess(response.data));
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const deleteRescanSavedVulnerability = (scanId, payload) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.delete(
        `${clientVulnerabilityApiEndpoint}/re-scan/${scanId}`,
        {
          data: payload,
        }
      );
      dispatch(
        slice.actions.deleteRescanSavedVulnerabilitySuccess(response.data)
      );
      return response;
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const handleSubmitScanToConsultant = (scanId, description) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.put(
        `${clientScanApiEndpoint}/submit-scan/${scanId}`,
        { commentDesc: description }
      );
      dispatch(
        slice.actions.updateSubmitScanToConsultantSuccess(response.data)
      );
      notificationManager(response);
      return response;
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getPublishedVulnerabilityEvidenceListSuccess = (
  vulnerabilityId
) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(
        `${clientEvidenceApiEndpoint}/evidence-list/${vulnerabilityId}`
      );
      dispatch(
        slice.actions.getPublishedVulnerabilityEvidenceListSuccess(
          response.data
        )
      );
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getDownloadSelectedPublishedVulEvidence = (
  vulnerabilityEvidenceId
) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(
        `${clientEvidenceApiEndpoint}/download/${vulnerabilityEvidenceId}`
      );
      dispatch(
        slice.actions.getDownloadSelectedPublishedVulEvidenceSuccess(
          response.data
        )
      );
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getSelectedRescanVulnerability = (cvId) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(
        `${clientVulnerabilityApiEndpoint}/${cvId}`
      );
      dispatch(
        slice.actions.getSelectedRescanVulnerabiitySuccess(response.data)
      );
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const handleRescanVulnerabilityStatus = (scanId, payload) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.put(
        `${clientVulnerabilityApiEndpoint}/update/${scanId}`,
        payload
      );
      dispatch(
        slice.actions.updateRescanVulnerabilityStatusSuccess(response.data)
      );
      notificationManager(response);
      return response;
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const fetchAgeBuckets = (params) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(
        `${clientReportsApiEndpoint}/vulnerability/age-buckets`,
        { params }
      );
      dispatch(slice.actions.fetchAgeBucketsSuccess(response.data));
      return response;
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const VulnerabilitiesExport = (params) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(
        `${clientReportsApiEndpoint}/vulnerability/age-buckets`,
        { params }
      );
      dispatch(slice.actions.VulnerabilitiesExportSuccess(response.data));
      return response;
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getScanStatusFilterList = () => {
  return async () => {
    dispatch(setLoading(true));

    const url =
      localStorage.getItem("organizationType") === "CL"
        ? `${clientScanApiEndpoint}/scan-status/list`
        : `consultant/v1/scan/all-status`;
    try {
      const response = await axios.get(url);
      dispatch(slice.actions.getScanStatusFilterListSuccess(response.data));
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const exportPushlishedVulnerability = (params) => {
  return async () => {
    dispatch(setLoading(true));

    const url =
      localStorage.getItem("organizationType") === "CL"
        ? `${clientVulnerabilityApiEndpoint}/published/export`
        : `${consultantEndpointApi}/published/vul/list/export`;
    try {
      const response = await axios.get(url, {
        params,
      });
      dispatch(
        slice.actions.exportPushlishedVulnerabilitySuccess(response.data)
      );
      notificationManager(response);
    } catch (error) {
      dispatch(openSnackbar(error));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
